/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Bell, Checks, Download } from '@saleshandy/icons';
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Spinner as Loader,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProgressBar } from '@saleshandy/design-system';
import {
  Notifications,
  NotificationType,
} from '../../../../../components/home/types';
import { getRelativeDate } from '../../../../utils/time-conversions';
import { Images } from '../../../../app-constants';
import ImageIcon from '../../../../components/images/image-icon';
import { useOutClickHook } from '../../../../utils/hooks';
import {
  isProspectBulkOrSequenceBulk,
  getImageIcon,
  getNotificationClassName,
  getNotificationReason,
  getNotificationType,
  getRedirectUrl,
  getRegexForEmail,
  moreNotificationExist,
  isAsyncOperationNotification,
  asyncNotificationTypes,
  prospectBulkAndSequence,
  bulkSequenceAndDomainNotificationTypes,
  pausedNotifications,
  isCompletedOperationNotification,
  isLogVisibleNotificationTypes,
} from './helper/notification-helper';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import history from '../../../../history';
import hasPermission from '../../../../utils/access-control/has-permission';
import { Permissions } from '../../../../utils/access-control/enums/permissions';
import { AnalyticsEvents } from '../../../../enums/analytics';
import Spinner from '../custom-spinner';
import { getIsRequestPending, redirectWithRefresh } from '../../../../utils';
import { SpinnerAnimation } from '../spinner/spinner';
import {
  getEmailAccount,
  updateEmailAccountStatus,
} from '../../../../../components/email-accounts/helpers/email-account.api';
import {
  isSavedSMTPAccount,
  getEmailAccountType,
} from '../../../../../components/email-accounts/components/email-accounts-content/utils/helpers';
import {
  EmailAccountMethod,
  EmailAccountsSortBy,
  EmailAccountType,
} from '../../../../../components/email-accounts/enums/email-account';
import {
  EmailAccount,
  EmailAccountStatus,
} from '../../../../../components/email-accounts/types/email-account';
import { ResourceIdentifiers } from '../../../../utils/access-control/enums/resource-identifiers';
import hasResource from '../../../../utils/access-control/has-resource';
import { Order } from '../../../../enums/order';
import { constants } from '../../../../enums/constants';
import toaster, { Theme } from '../../../../toaster';
import useBaseRoute from '../../../../hooks/use-base-route';
import { getProgressBarPercentage } from '../../../../../components/home/components/header/header-helper';
import { RootState } from '../../../../../store/root-reducer';
import NotificationBadge from './notification-badge';
import { IProps } from './type';
import NotificationTextAsync from './notification-text-async';

const Notification: React.FC<IProps> = ({
  notifications,
  pollingNotifications,
  markAsRead,
  notificationCount,
  fetchNextNotifications,
  timeZone,
  sendGetUserUnreadNotification,
  userUnreadNotificationCount,
  trackingId,
  isHasPermissionToCallUnreadNotification,
  resetNotificationData,
  getUserNotificationsDataRequestStatus,
  sendConnectEmailAccountRequest,
  sendGetEmailAccountRequest,
  sendGetEmailAccountsRequest,
}) => {
  const blinkNotificationIcon = useSelector(
    (state: RootState) => state?.home?.blinkNotificationIcon,
  );
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(false);
  const notificationsLength = notifications?.length;

  const toggleNotifications = () => {
    window.analytics?.track?.({
      userId: trackingId,
      event: AnalyticsEvents.NotificationClicked,
      properties: {
        current_tab_url: document.location.href,
      },
    });

    setShowNotifications(!showNotifications);
    if (!showNotifications) {
      resetNotificationData();
    }
  };

  const closeNotifications = () => setShowNotifications(false);

  const { t } = useTranslation();

  const route = history.location.pathname;
  const { baseRoute, isBase } = useBaseRoute(route);

  const notificationRef = useRef(null);
  useOutClickHook(notificationRef, closeNotifications);

  const markAsReadAndDownload = async (id: string, reportUrl: string) => {
    await markAsRead(id);
    window.location.href = reportUrl;
  };
  const markAsReadAndRedirect = async (id: string, redirectUrl: string) => {
    await markAsRead(id);
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    if (isHasPermissionToCallUnreadNotification && !isBase) {
      sendGetUserUnreadNotification();
    }
  }, []);

  useEffect(() => {
    if (isHasPermissionToCallUnreadNotification && isBase) {
      sendGetUserUnreadNotification();
    }
  }, [baseRoute, isBase]);

  useEffect(() => {
    setShowUnreadNotifications(false);
    if (notificationsLength > 0) {
      const unread = notifications.some(
        (notification) => !notification.hasRead,
      );
      setShowUnreadNotifications(unread);

      setHasMoreNotifications(
        moreNotificationExist(notificationsLength, notificationCount),
      );
    }
  }, [notifications]);

  const addEmailAccountHandler = (
    method: EmailAccountMethod,
    emailAccountId: number,
  ) => {
    hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      sendConnectEmailAccountRequest(method, emailAccountId);
  };

  const initiateEmailAccountConnectionFlow = async (
    emailAccount: EmailAccount,
  ) => {
    if (
      isSavedSMTPAccount(emailAccount) ||
      emailAccount.type === EmailAccountType.SmtpImap
    ) {
      redirectWithRefresh(
        `/email-accounts?connect-smtp=1&emailAccountId=${emailAccount.id}&emailAccountType=${EmailAccountType.SmtpImap}`,
      );
      // eslint-disable-next-line no-useless-return
      return;
      // eslint-disable-next-line no-else-return
    }
    addEmailAccountHandler(getEmailAccountType(emailAccount), emailAccount.id);
  };

  const onUpdateEmailAccountHandler = async (
    emailAccountStatus: EmailAccountStatus,
    emailAccount: EmailAccount,
  ) => {
    const response = await updateEmailAccountStatus(
      // active or paused
      emailAccount.id,
      emailAccountStatus,
    );
    const newEmailStatus = response.payload.emailAccountStatus;
    if (newEmailStatus === EmailAccountStatus.InActive) {
      // initiate connection flow
      initiateEmailAccountConnectionFlow(emailAccount);
      return;
    }
    // call the email list api and return;
    sendGetEmailAccountRequest(emailAccount.id);
    sendGetEmailAccountsRequest({
      clientIds: [],
      emailServiceProvider: [],
      tagIds: [],
      sequenceIds: [],
      status: [],
      owners: [],
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
      search: '',
      sortByKey: EmailAccountsSortBy.SetupScore,
      order: Order.Desc,
    });
    toaster.success('Email Account enabled successfully', {
      theme: Theme.New,
    });
  };

  const onEmailAccountPausedClickHandler = async (emailAccountId: number) => {
    try {
      const emailAccountRequest = await getEmailAccount(emailAccountId);
      const emailAccountData = emailAccountRequest?.payload || {};
      const currentEmailAccountStatus = emailAccountData?.status;
      if (currentEmailAccountStatus === EmailAccountStatus.Active) {
        toaster.warning('Email Account is already active', {
          theme: Theme.New,
        });
      } else if (currentEmailAccountStatus === EmailAccountStatus.InActive) {
        initiateEmailAccountConnectionFlow(emailAccountData);
      } else if (emailAccountData?.id) {
        onUpdateEmailAccountHandler(
          EmailAccountStatus.Active,
          emailAccountData,
        );
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const getUpdatedProgressData = (notification: Notifications) => {
    // Find matching polling notification by id
    const matchingPollingNotification = pollingNotifications?.notifications?.find(
      (pollingNotif) => pollingNotif._id === notification._id,
    );

    // If found matching notification, update the progress data
    return matchingPollingNotification || notification;
  };

  const NotificationTooltip = (popoverText) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {popoverText}
    </Tooltip>
  );
  return (
    hasPermission(Permissions.NOTIFICATION_READ) && (
      <div ref={notificationRef}>
        <OverlayTrigger
          placement="bottom"
          overlay={NotificationTooltip(t('labels.notifications'))}
        >
          <div
            className={getNotificationClassName(
              showNotifications,
              blinkNotificationIcon || false,
            )}
            {...accessibleOnClick(toggleNotifications)}
          >
            {(showUnreadNotifications || userUnreadNotificationCount) &&
            userUnreadNotificationCount > 0 ? (
              <>
                <NotificationBadge
                  userUnreadNotificationCount={userUnreadNotificationCount}
                />
              </>
            ) : (
              <></>
            )}
            <Bell height={20} width={20} className="d-flex notification" />
          </div>
        </OverlayTrigger>

        {showNotifications && (
          <div
            className="notification-view-area text-left"
            id="scrollable-notification"
          >
            <Row className="px-3 py-2 border-bottom no-margin">
              <Col xs={6} className="no-padding">
                <span className="notification-font">
                  {t('labels.notifications')}
                </span>
              </Col>
              <Col xs={6} className="text-right no-padding">
                {notificationsLength && showUnreadNotifications ? (
                  <span
                    className="notification-font mark-read"
                    {...accessibleOnClick(() => markAsRead())}
                  >
                    {t('labels.mark_all_as_read')}
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <InfiniteScroll
              dataLength={notificationsLength}
              hasMore={hasMoreNotifications}
              next={fetchNextNotifications}
              loader={
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              }
              endMessage={
                notificationsLength ? (
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <p className="notification-end-text">The End</p>
                  </div>
                ) : (
                  <span />
                )
              }
              scrollableTarget="scrollable-notification"
            >
              {notifications && notificationsLength > 0 ? (
                notifications.map((notification) => {
                  const pollingData = getUpdatedProgressData(notification);
                  if (pollingData) {
                    notification = pollingData;
                  }
                  return (
                    <Row
                      className="notification-row py-3 no-margin border-bottom"
                      key={notification._id}
                    >
                      <Col
                        xs={1}
                        className="text-center d-flex justify-content-center"
                      >
                        <div
                          className={`${
                            ((pollingData?.reportUrl ??
                              notification?.reportUrl) ||
                              prospectBulkAndSequence?.includes(
                                notification?.notificationType,
                              )) &&
                            !asyncNotificationTypes?.includes(
                              notification?.notificationType,
                            )
                              ? 'notification-green'
                              : notification.notificationType ===
                                  NotificationType.LeadRevealCompleted ||
                                notification.notificationType ===
                                  NotificationType.LeadRevealCompletedNoEmailOrPhone ||
                                (pollingData?.completedAt &&
                                  isAsyncOperationNotification(
                                    notification?.notificationType,
                                  ))
                              ? 'notification-purple'
                              : !notification?.completedAt &&
                                isAsyncOperationNotification(
                                  notification?.notificationType,
                                )
                              ? 'notification-gray'
                              : 'notification-red'
                          } d-flex bs-badge`}
                        >
                          {!notification.hasRead && (
                            <sup
                              data-show="true"
                              className={`bs-scroll-number ${
                                ((pollingData?.reportUrl ??
                                  notification?.reportUrl) ||
                                  prospectBulkAndSequence?.includes(
                                    notification?.notificationType,
                                  )) &&
                                !asyncNotificationTypes?.includes(
                                  notification?.notificationType,
                                )
                                  ? 'bs-badge-status bs-badge-status-success bs-badge-dot'
                                  : notification.notificationType ===
                                      NotificationType.LeadRevealCompleted ||
                                    notification.notificationType ===
                                      NotificationType.LeadRevealCompletedNoEmailOrPhone ||
                                    (pollingData?.completedAt &&
                                      isAsyncOperationNotification(
                                        notification?.notificationType,
                                      ))
                                  ? 'bs-badge-purple-dot'
                                  : !notification?.completedAt &&
                                    isAsyncOperationNotification(
                                      notification?.notificationType,
                                    )
                                  ? 'bs-badge-gray-dot'
                                  : 'bs-badge-dot'
                              } custom-position-icon`}
                            />
                          )}
                          {((pollingData?.reportUrl ??
                            notification?.reportUrl) ||
                            bulkSequenceAndDomainNotificationTypes?.includes(
                              notification?.notificationType,
                            )) &&
                          !asyncNotificationTypes?.includes(
                            notification?.notificationType,
                          ) ? (
                            <Checks
                              width={20}
                              height={20}
                              className="green-txt-14"
                            />
                          ) : (
                            <ImageIcon
                              src={getImageIcon(pollingData || notification)}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={9}>
                        <p className="notification-font">
                          {t(
                            `labels.${getNotificationReason(
                              pollingData || notification,
                            )}`,
                          )}
                        </p>
                        {isLogVisibleNotificationTypes.includes(
                          notification?.notificationType,
                        ) && (
                          <p className="notification-content">
                            <NotificationTextAsync
                              notificationText={
                                pollingData?.log || notification?.log || ''
                              }
                              notification={pollingData || notification}
                            />
                          </p>
                        )}

                        {pausedNotifications?.includes(
                          notification?.notificationType,
                        ) && (
                          <p
                            className="notification-content"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: notification?.log?.replace(
                                getRegexForEmail(notification),
                                '<span>$1</span>',
                              ),
                            }}
                          />
                        )}
                        {isAsyncOperationNotification(
                          notification?.notificationType,
                        ) && (
                          <div className="notification-progress-bar">
                            {pollingNotifications?.notifications?.length > 0 &&
                              pollingNotifications?.notifications
                                .filter((n) => n._id === notification._id)
                                .map((n) =>
                                  !n.completedAt ? (
                                    <ProgressBar
                                      key={n._id}
                                      percentage={getProgressBarPercentage(
                                        getUpdatedProgressData(notification)
                                          ?.metaData?.completedCount,
                                        getUpdatedProgressData(notification)
                                          ?.metaData?.totalCount,
                                      )}
                                    />
                                  ) : (
                                    <></>
                                  ),
                                )}
                            {(notification.notificationType ===
                              NotificationType.ProspectImportAsync ||
                              notification.notificationType ===
                                NotificationType.SequenceProspectImportAsync) &&
                              notification?.reportUrl && (
                                <div
                                  className="download-report cursor-pointer"
                                  {...accessibleOnClick(() =>
                                    markAsReadAndDownload(
                                      notification._id,
                                      notification?.reportUrl,
                                    ),
                                  )}
                                >
                                  <Download />
                                  <span className="download-report-text">
                                    Download Failed Report
                                  </span>
                                </div>
                              )}
                          </div>
                        )}
                        <p className="date-time-content">
                          {
                            getRelativeDate(
                              notification.timestamp,
                              timeZone,
                              true,
                            ).value
                          }
                        </p>
                      </Col>
                      <Col
                        xs={2}
                        className="text-right no-padding action-button-wrap margin-adjust"
                      >
                        {notification?.reportUrl &&
                        notification?.notificationType ===
                          NotificationType.DomainRegistrationComplete ? (
                          <a
                            className="action-button view-email-accounts-btn"
                            {...accessibleOnClick(() =>
                              markAsReadAndRedirect(
                                // eslint-disable-next-line no-underscore-dangle
                                notification._id,
                                getRedirectUrl(notification),
                              ),
                            )}
                          >
                            View Email Accounts
                          </a>
                        ) : notification?.notificationType ===
                            NotificationType.DomainRegistrationInProgress ||
                          notification.notificationType ===
                            NotificationType.MailboxRegistrationComplete ? (
                          <></>
                        ) : (pollingData?.reportUrl ??
                            notification?.reportUrl) &&
                          !asyncNotificationTypes?.includes(
                            notification?.notificationType,
                          ) ? (
                          <a
                            className="action-button mt-2"
                            download
                            target="_blank"
                            rel="noreferrer"
                            {...accessibleOnClick(() =>
                              markAsReadAndDownload(
                                notification._id,
                                notification?.reportUrl,
                              ),
                            )}
                          >
                            {t(`labels.${getNotificationType(notification)}`)}
                          </a>
                        ) : [NotificationType.EmailAccountPaused].includes(
                            notification.notificationType,
                          ) ? (
                          <div
                            className="action-button mt-2"
                            {...accessibleOnClick(() =>
                              onEmailAccountPausedClickHandler(
                                notification.emailAccountId,
                              ),
                            )}
                          >
                            {getNotificationType(notification)
                              ? t(`labels.${getNotificationType(notification)}`)
                              : ''}
                          </div>
                        ) : isProspectBulkOrSequenceBulk(
                            notification?.notificationType,
                          ) &&
                          notification?.reportUrl !== '' &&
                          isAsyncOperationNotification(
                            notification?.notificationType,
                          ) ? (
                          <a
                            className={`action-button mt-2 ${
                              getUpdatedProgressData(notification)?.metaData
                                ?.completedCount > 0
                                ? ''
                                : 'disabled'
                            }`}
                            {...(getUpdatedProgressData(notification)?.metaData
                              ?.completedCount > 0
                              ? accessibleOnClick(() =>
                                  markAsReadAndRedirect(
                                    notification._id,
                                    getRedirectUrl(notification),
                                  ),
                                )
                              : accessibleOnClick(() => {}))}
                          >
                            {getNotificationType(notification)
                              ? t(`labels.${getNotificationType(notification)}`)
                              : ''}
                          </a>
                        ) : (
                          isCompletedOperationNotification.includes(
                            notification?.notificationType,
                          ) && (
                            <a
                              className="action-button mt-2"
                              {...accessibleOnClick(() =>
                                markAsReadAndRedirect(
                                  // eslint-disable-next-line no-underscore-dangle
                                  notification._id,
                                  getRedirectUrl(notification),
                                ),
                              )}
                            >
                              {getNotificationType(notification)
                                ? t(
                                    `labels.${getNotificationType(
                                      notification,
                                    )}`,
                                  )
                                : ''}
                            </a>
                          )
                        )}
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <>
                  {getIsRequestPending(
                    getUserNotificationsDataRequestStatus,
                  ) ? (
                    <div className="d-flex justify-content-center align-items-center NF_loder">
                      <Loader
                        animation={SpinnerAnimation.Border}
                        className="spinner--blue"
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center no-notifications">
                      <div>
                        <ImageIcon src={Images.NoNotifications} />
                        <p className="no-notification-text mt-3">
                          {t('labels.noNotifications')}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
    )
  );
};

export default Notification;
