import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle, BadgeCheck, BadgeCross, InfoCircle, Link as LinkIcon, Pencil, Refresh } from '@saleshandy/icons';
import { Link } from 'react-router-dom';
import { Pills } from '@saleshandy/design-system';
import HeaderBanner from '../header-banner';
import AdminSettingToggle from './components/admin-setting-toggle';
import Spinner from '../../../../shared/design-system/components/atoms/spinner';
import toaster, { Theme } from '../../../../shared/toaster';
import { showGeneralErrorNotification } from '../../../../shared/utils/errors';
import {
  executeOnRequestStatus,
  executeOnErrorWithErrorCheck,
} from '../../../../shared/utils/execute-on-request-status';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { ShAccountSettingsCode } from '../../enums/admin-settings';
import {
  getAdminSettingsValues,
  getEmailVerificationModalContent,
  getProspectDuplicationAllowedModalContent,
  getToggleOnOrOffMessage,
  getProspectSharingModalContent,
  getProspectSharingOnOffMessage,
  getAutoAddToProspectOnLeadRevealOnOffMessage,
  getAutoAddToProspectOnLeadRevealModalContent,
  checkLifeTimeSubcriptionPlan,
} from './utils/helper';
import { IProps, PaginationOptions } from './types';

import { getIsRequestPending } from '../../../../shared/utils/get-request-status';
import { SpinnerAnimation } from '../../../../shared/design-system/components/atoms/spinner/spinner';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { GlobalSettings } from '../../../../shared/components/global-settings-wrapper';
import SequenceSetting from './components/admin-setting-sequence';
import AdminSettingSection from './components/admin-setting-section';
import { supportUrls } from '../../../../shared/utils/urls';
import AdminSettingExclusionInput from './components/admin-setting-exclusion-input';
import { getExclusionListPaginationInitialState } from './admin-settings-slice';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { Routes } from '../../../../shared/routes';
import Input from '../../../../shared/design-system/components/input';
import EmailsAndDomainsInput from '../do-not-contact/components/do-not-contact-list-details/components/do-not-contact-list-details-input/emails-and-domains-input';
import DataTable from '../../../../shared/components/data-table/data-table';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { isAgencyUser } from '../../../../shared/utils/user-details';
import ProspectTagsRow from '../../../../shared/design-system/components/molecules/prospect-tags-row';

const AdminSettings: React.FC<IProps> = ({
  adminSettings,
  resetAdminSettings,

  getAdminSettingsRequestStatus,
  getAdminSettingsRequestError,
  sendGetAdminDetailsRequest,

  updateAdminSettingsStatus,
  updateAdminSettingsMessage,
  updateAdminSettingsError,
  sendUpdateAdminDetailsRequest,
  resetUpdateAdminSettings,

  integrationAdminSetting,
  getIntegrationAdminSettingRequestStatus,
  changeIntegrationSettingRequestStatus,
  getIntegrationSetting,
  sendChangeIntegrationSetting,

  sendUpdateSSOOrganizationRequest,
  updateSSOOrganizationRequestStatus,
  updateSSOOrganizationRequestError,

  sendGetSSOOrganizationRequest,

  sendGetSSOSetupUrlRequest,
  getSSOSetupUrlRequestStatus,
  getSSOSetupUrlRequestError,

  subscriptionPlan,
  agencyConfig,
  atmRole,
  ssoSetupUrl,
  organizationData,
  organizationId,

  sendGetExclusionListRequest,
  exclusionList,
  exclusionListPaginationOptions,
  resetExclusionList,
  sendGetUserSettingsRequest,
}) => {
  const { t } = useTranslation();
  const shouldFetchExclusionList = useRef<boolean>(true);

  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [prospectDuplicationModal, setProspectDuplicationModal] = useState(
    false,
  );
  const [
    isProspectSharingConfirmationModalVisible,
    setIsProspectSharingConfirmationModalVisible,
  ] = useState(false);
  const [
    autoAddToProspectOnLeadRevealConfirmationModalVisible,
    setAutoAddToProspectOnLeadRevealConfirmationModalVisible,
  ] = useState(false);

  const [integrationSetting, setIntegrationSetting] = useState(
    integrationAdminSetting,
  );

  const [showSSOEnableWarningModal, setShowSSOEnableWarningModal] = useState(false);

  const [ssoDisplayName, setSSODisplayName] = useState<string>("");
  const [isSSODisplayNameError, setIsSSODisplayNameError] = useState<boolean>(false);
  const [ssoDomains, setSSODomains] = useState<string[]>([]);
  const [isSSODomainError, setIsSSODomainError] = useState<boolean>(false);

  const [showSSOSetUpModal, setShowSSOSetUpModal] = useState(false);

  const [showSSOSetupLinkModal, setShowSSOSetupLinkModal] = useState(false);
  const [isUserRequestedSSOUrl, setIsUserRequestedSSOUrl] = useState(false);
  const [isSSOSetupInProgress, setIsSSOSetupInProgress] = useState(false);

  const hasSSOValidPlan = (subPlan: string): boolean => 
    subPlan.indexOf('outreach-scale') > -1

  const onHideEmailVerificationModal = () => {
    setEmailVerificationModal(false);
  };

  const onHideProspectDuplicationModal = () => {
    setProspectDuplicationModal(false);
  };

  const onHideProspectSharingModal = () => {
    setIsProspectSharingConfirmationModalVisible(false);
  };

  const onToggle = (code: ShAccountSettingsCode) => {
    if (code === ShAccountSettingsCode.IsVerificationActive) {
      setEmailVerificationModal(true);
    }
    if (code === ShAccountSettingsCode.ProspectDuplicationAllowed) {
      setProspectDuplicationModal(true);
    }
    if (code === ShAccountSettingsCode.ProspectSharing) {
      setIsProspectSharingConfirmationModalVisible(true);
    }
    if (code === ShAccountSettingsCode.AutoAddToProspectOnLeadReveal) {
      setAutoAddToProspectOnLeadRevealConfirmationModalVisible(true);
    }
    if (code === ShAccountSettingsCode.IntegrationSetting) {
      sendChangeIntegrationSetting(integrationSetting === 1);
    }
  };

  const onSettingToggle = (code: ShAccountSettingsCode, value: boolean) => {
    if (
      getIsRequestPending(updateAdminSettingsStatus) ||
      getIsRequestPending(getAdminSettingsRequestStatus)
    ) {
      return;
    }

    const settings = [
      {
        code,
        value: value ? '1' : '0',
      },
    ];

    sendUpdateAdminDetailsRequest({
      settings,
    });
  };

  const handleGetExclusionListRequest = (
    paginationOption: PaginationOptions,
  ) => {
    sendGetExclusionListRequest(paginationOption);
  };

  const onShowSSOSetUpModal = () => {
    if(organizationData) {
      setSSODisplayName(organizationData.displayName);
      setSSODomains(organizationData.domains);
    }
    setShowSSOSetUpModal(true);
  }

  const getSSOTogleLabel = (subPlan: string) => {
    if (hasSSOValidPlan(subPlan)) {
      return (
        <span>
          Enable Single-Sign On for seamless access for all the users with just one login {' '}
          <a href={supportUrls.ssoSetUpBlog} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </span>
      );
    }
    return (
      <span className='sso-toggle-label'>
        <span className='sso-toggle-label-disable'>
          To enable SSO Upgrade your plan to 
        </span>
        <Link to={Routes.Setting_Billing_Upgrade_Email_Outreach_Plan}>Scale or above</Link>
      </span>
    );
  }


  const getSSOSetUpContent = () : ReactNode => 
    <div className='sso-setup-form-container'>
      <form>
        <Input
          name="displayName"
          label="Display name"
          placeholder="Enter display name"
          value={ssoDisplayName}
          onChange={(value)=> {
            setSSODisplayName(value)
            setIsSSODisplayNameError(false)
          }}
          className="admin-setting-input"
          variant={isSSODisplayNameError && Input.Variant.Error}
          caption={isSSODisplayNameError && 'Display name is required'}
        />

        <div className='do-not-contact-list-details--input-wrapper admin-setting-sso-domain--input'>
          <div className='admin-setting-sso-input--label-wrapper'>
            <span className='admin-setting-sso-input--label'>Authorized Domain for Login</span>
              <OverlayTooltip 
                text="SSO app will only allow SAML logins from users whose email are in this list of domains"
                placement={Placement.Right}
              >
                <InfoCircle color='#6B7280' />
            </OverlayTooltip>
          </div>
          
          <EmailsAndDomainsInput 
            emailsAndDomains={ssoDomains}
            setEmailsAndDomains={setSSODomains}
            isError={isSSODomainError}
            setIsError={setIsSSODomainError}
            isMaxedEmailAdded={false}
            placeholder='Enter a domain'
          />
          
        </div>
      </form>
    </div>

  const getSSOSetupLinkContent = () : ReactNode => 
    <div className='sso-setup-link-container'>
      <p className='sso-setup-link-container--text'>
        Make sure you copy this link to configure your SAML settings, as it’s valid for one time use only.
      </p>
      <div className='copy-content'>
        <span>{ssoSetupUrl}</span>
      </div>
    </div>

  const getSSOLoginColumns = () => 
    [
      {
        title: 'Organization ID',
        dataIndex: 'organizationId',
        key: 'organizationId',
        align: 'left',
        sticky: true,

      },
      {
        title: 'Display Name',
        dataIndex: 'displayName',
        key: 'displayName',
        align: 'left',
      },
      {
        title: 'Account ID',
        dataIndex: 'accountId',
        key: 'accountId',
        align: 'left',
      },
      {
        title: 'Domains',
        dataIndex: 'domains',
        key: 'domains',
        align: 'left',
        render: (cell) => <ProspectTagsRow tags={cell} />
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        render: (status: boolean) => (
          <div className='sso-status'>
            {status
            ? <>
                <BadgeCheck height='20' width='20' color='green' /> 
                <span>Configured</span>
              </>
            : <>
                <BadgeCross height='20' width='20' color='red' />
                <span>Not Configured</span>
              </>
          }
          </div>
        )
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'left',
        render: () => (
          <div className='sso-actions'>
            {
              organizationData.isSSOActiveAndEnabled ? 
              <div className='sso-actions--refresh-disabled'>
                <Refresh height='20' width='20' />
              </div>
              :
              <OverlayTooltip text='Refresh'>
                <div {...accessibleOnClick(()=> sendGetSSOOrganizationRequest())}>
                  <Refresh height='20' width='20' />
                </div>
              </OverlayTooltip>
            }
            <OverlayTooltip text='Edit'>
              <div {...accessibleOnClick(()=> onShowSSOSetUpModal())}>
                <Pencil height='20' width='20' />
              </div>
            </OverlayTooltip>
          </div>
        )
      }
    ];

  const getSSOLoginData = () => {
    const domains = organizationData.domains?.map((domain, ind)=> {
      return { name: domain};
    })
    return [
      {
        organizationId: organizationData.organizationId,
        displayName: organizationData.displayName,
        accountId: organizationData.accountId,
        domains: domains,
        status: organizationData.isSSOActiveAndEnabled,
      },
    ];
  }

  useEffect(() => {
    sendGetAdminDetailsRequest();
    getIntegrationSetting();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateAdminSettingsStatus,
      onSuccess: () => {
        toaster.success(toastMessage || updateAdminSettingsMessage, {
          theme: Theme.New,
        });
        setToastMessage('');
        if (!isSSOSetupInProgress) {
          sendGetAdminDetailsRequest();
        }
        resetUpdateAdminSettings();
        emailVerificationModal && setEmailVerificationModal(false);
        prospectDuplicationModal && setProspectDuplicationModal(false);
        isProspectSharingConfirmationModalVisible &&
          setIsProspectSharingConfirmationModalVisible(false);
        autoAddToProspectOnLeadRevealConfirmationModalVisible &&
          setAutoAddToProspectOnLeadRevealConfirmationModalVisible(false);
        if (ssoSetupUrl && showSSOSetUpModal) {
          setShowSSOSetUpModal(false);
          setShowSSOSetupLinkModal(true);
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateAdminSettingsError,
          onError: () => {
            showGeneralErrorNotification(updateAdminSettingsError.message);
            resetUpdateAdminSettings();
          },
        });
      },
    });
  }, [updateAdminSettingsStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getIntegrationAdminSettingRequestStatus,
      onSuccess: () => {
        setIntegrationSetting(integrationAdminSetting);
      },
    });
  }, [getIntegrationAdminSettingRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: changeIntegrationSettingRequestStatus,
      onSuccess: () => {
        getIntegrationSetting();
      },
    });
  }, [changeIntegrationSettingRequestStatus]);

  useEffect(
    () => () => {
      resetAdminSettings();
      resetExclusionList();
    },
    [],
  );

  const {
    isVerificationActive,
    prospectDuplicationAllowed,
    prospectSharing,
    AutoAddToProspectOnLeadReveal,
    isAICategorization,
    trackUniboxOpen,
    trackUniboxClick,
    isAddProspectFromBusinessDomain,
    trackExternalMails,
    enableExclusionList,
    isSSOEnabled,
    isMFAEnabled,
  } = getAdminSettingsValues(adminSettings);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAdminSettingsRequestStatus,
      onSuccess: () => {
        setIsInitialRequest(false);

        if (
          enableExclusionList &&
          trackExternalMails &&
          shouldFetchExclusionList.current
        ) {
          shouldFetchExclusionList.current = false;
          handleGetExclusionListRequest(
            getExclusionListPaginationInitialState(),
          );
        }

        if (isSSOEnabled && hasPermission(Permissions.ACCOUNT_SSO_SETTING_READ)) {
          sendGetSSOOrganizationRequest();
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getAdminSettingsRequestError,
          onError: () => {
            showGeneralErrorNotification(getAdminSettingsRequestError.message);
          },
        });
      },
    });
  }, [getAdminSettingsRequestStatus]);

  const handleLoadMore = () => {
    if (
      exclusionListPaginationOptions.currentPage >=
      exclusionListPaginationOptions.totalPages
    ) {
      return;
    }
    if (
      exclusionListPaginationOptions.currentPage <
      exclusionListPaginationOptions.totalPages
    ) {
      handleGetExclusionListRequest({
        ...getExclusionListPaginationInitialState(),
        currentPage: exclusionListPaginationOptions.currentPage + 1,
      });
    }
  };

  const handleSSOOrganizationCreation = () => {
    if (ssoDisplayName === '') {
      setIsSSODisplayNameError(true);
      return;
    }

    if (ssoDomains.length === 0) {
      setIsSSODomainError(true);
      return;
    }

    if (isSSOEnabled) {
      sendUpdateSSOOrganizationRequest({
        displayName: ssoDisplayName,
        domains: ssoDomains,
      });
    } else {
      if (
        getIsRequestPending(updateAdminSettingsStatus) ||
        getIsRequestPending(getAdminSettingsRequestStatus)
      ) {
        return;
      }

      const settings = [
        {
          code: ShAccountSettingsCode.IsSSOEnabled,
          value: isSSOEnabled ? '0' : '1',
        },
      ];

      const ssoOrganizationData = [
        {
          displayName: ssoDisplayName,
          domains: ssoDomains,
        }
      ]

      sendUpdateAdminDetailsRequest({
        settings,
        ssoOrganizationData
      });
    }
  };

  const handleGetSSOSetupUrl = () => {
    setIsUserRequestedSSOUrl(true);
    sendGetSSOSetupUrlRequest();
  };

  const handleSSOSetupLinkModalonClose = () => {
    setShowSSOSetupLinkModal(false);
    setIsSSOSetupInProgress(false);
    sendGetAdminDetailsRequest();
    sendGetUserSettingsRequest();
  }

  const handleToggleSSO = () => {
    if (!isSSOEnabled) {
      setShowSSOEnableWarningModal(true);
      if (!organizationId) {
        setIsSSOSetupInProgress(true);
      }
    } else {
      onSettingToggle(ShAccountSettingsCode.IsSSOEnabled, !isSSOEnabled);
    }
  }

  const handleSSOEnableWarningSumbit = () => {
    if (organizationId) {
      onSettingToggle(ShAccountSettingsCode.IsSSOEnabled, !isSSOEnabled);
      setShowSSOEnableWarningModal(false);
    } else {
      setShowSSOEnableWarningModal(false);
      onShowSSOSetUpModal();
    }
  }

  useEffect(() => {
    executeOnRequestStatus({
      status: getSSOSetupUrlRequestStatus,
      onSuccess: () => {
        if (isUserRequestedSSOUrl) {
          setShowSSOSetupLinkModal(true);
          setIsUserRequestedSSOUrl(false); // Reset for next time
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getSSOSetupUrlRequestError,
          onError: () => {
            showGeneralErrorNotification(getSSOSetupUrlRequestError.message);
          }
        })
      },
    })
  }, [getSSOSetupUrlRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateSSOOrganizationRequestStatus,
      onSuccess: () => {
        setShowSSOSetUpModal(false);
        sendGetAdminDetailsRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateSSOOrganizationRequestError,
          onError: () => {
            showGeneralErrorNotification(updateSSOOrganizationRequestError.message);
            resetAdminSettings();
          }
        })
      },
    })
  }, [updateSSOOrganizationRequestStatus]);

  return (
    <>
      <GlobalSettings.Header>
        <GlobalSettings.HeaderLeft>
          <GlobalSettings.HeaderTitle title="Admin Settings" />
        </GlobalSettings.HeaderLeft>
      </GlobalSettings.Header>
      {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
      <GlobalSettings.Content className="admin-settings">
        {getIsRequestPending(getAdminSettingsRequestStatus) &&
        isInitialRequest ? (
          <div className="admin-settings--spinner">
            <Spinner animation={SpinnerAnimation.Border} />
          </div>
        ) : (
          <div className="admin-settings--content">
            <AdminSettingSection title="Sequence">
              <SequenceSetting setToastMessage={setToastMessage} />
            </AdminSettingSection>
            <AdminSettingSection title="Unified Inbox">
              <AdminSettingToggle
                checked={isAICategorization}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.AICategorization,
                    !isAICategorization,
                  )
                }
                code={ShAccountSettingsCode.AICategorization}
                label="AI Categorization in Unified Inbox : Assign outcome to conversation based on reply content"
              />
              <AdminSettingToggle
                checked={trackUniboxOpen}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.TrackUniboxOpen,
                    !trackUniboxOpen,
                  )
                }
                code={ShAccountSettingsCode.TrackUniboxOpen}
                label="Track opens for emails sent from unified inbox"
              />
              <AdminSettingToggle
                checked={trackUniboxClick}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.TrackUniboxClick,
                    !trackUniboxClick,
                  )
                }
                code={ShAccountSettingsCode.TrackUniboxClick}
                label="Track clicks for emails sent from unified inbox"
              />
              <AdminSettingToggle
                checked={trackExternalMails}
                onChange={() => {
                  shouldFetchExclusionList.current = true;
                  onSettingToggle(
                    ShAccountSettingsCode.TrackExternalMails,
                    !trackExternalMails,
                  );
                }}
                code={ShAccountSettingsCode.TrackExternalMails}
                label="Track external replies"
                learnMoreLink={supportUrls.trackExternalEmailsBlog}
                nestedSettings={[
                  {
                    label: (
                      <>
                        Track all replies from recipients with the same domain
                        as your prospects
                        <OverlayTooltip
                          placement={Placement.RightStart}
                          text="For e.g. If your prospect is jake@abc.com, replies from john@abc.com will be tracked and shown in unified inbox as they have matching domain."
                          className="external-reply-tooltip"
                        >
                          <InfoCircle
                            width={14}
                            height={14}
                            className="all-replies-icon"
                          />
                        </OverlayTooltip>
                      </>
                    ),
                    checked: isAddProspectFromBusinessDomain,
                    onChange: () => {
                      onSettingToggle(
                        ShAccountSettingsCode.AddProspectFromBusinessDomain,
                        !isAddProspectFromBusinessDomain,
                      );
                    },
                    disabled: !trackExternalMails,
                  },
                  {
                    label: (
                      <>
                        Do not receive emails from specific domains or emails
                        <OverlayTooltip
                          placement={Placement.RightStart}
                          text='You can add a domain/email address below. For example, adding "example.com" will not show any external emails from that domain in your unified inbox. '
                          className="external-reply-tooltip"
                        >
                          <InfoCircle
                            width={14}
                            height={14}
                            className="all-replies-icon"
                          />
                        </OverlayTooltip>
                      </>
                    ),
                    checked: enableExclusionList,
                    onChange: () => {
                      shouldFetchExclusionList.current = true;
                      return onSettingToggle(
                        ShAccountSettingsCode.EnableExclusionList,
                        !enableExclusionList,
                      );
                    },
                    disabled: !trackExternalMails,
                  },
                ]}
              />

              {enableExclusionList && trackExternalMails && (
                <AdminSettingExclusionInput
                  exclusionList={exclusionList}
                  handleLoadMore={handleLoadMore}
                />
              )}
            </AdminSettingSection>

            <AdminSettingSection title="Lead Finder">
              <AdminSettingToggle
                checked={AutoAddToProspectOnLeadReveal}
                onChange={onToggle}
                code={ShAccountSettingsCode.AutoAddToProspectOnLeadReveal}
                label="Save revealed leads from Lead Finder as prospects automatically"
              />
            </AdminSettingSection>

            <AdminSettingSection title="Prospects">
              <AdminSettingToggle
                checked={isVerificationActive}
                onChange={onToggle}
                code={ShAccountSettingsCode.IsVerificationActive}
                label="Allow verifying prospects while importing"
              />
              <AdminSettingToggle
                checked={prospectDuplicationAllowed}
                onChange={onToggle}
                code={ShAccountSettingsCode.ProspectDuplicationAllowed}
                label="Allow adding one prospect in multiple sequences"
              />
            </AdminSettingSection>

            {agencyConfig?.agencyId === undefined && (
              <AdminSettingSection title="Team Management">
                <AdminSettingToggle
                  checked={prospectSharing}
                  onChange={onToggle}
                  code={ShAccountSettingsCode.ProspectSharing}
                  label="Allow team members to see prospects of each other"
                />
              </AdminSettingSection>
            )}

            <AdminSettingSection title="Integration and Webhooks">
              <AdminSettingToggle
                checked={integrationSetting === 2}
                onChange={onToggle}
                code={ShAccountSettingsCode.IntegrationSetting}
                label="Send Integration/Webhook events for entire account(Including user's account)"
              />
            </AdminSettingSection>

            {!isAgencyUser() &&
              <AdminSettingSection title="Security">
                { !checkLifeTimeSubcriptionPlan(subscriptionPlan) && atmRole === 'owner' &&
                  <div className='sso-toggle-container'>
                    <div className='sso-toggle'>
                      <AdminSettingToggle
                        onChange={handleToggleSSO}
                        checked={isSSOEnabled}
                        code={ShAccountSettingsCode.IsSSOEnabled}
                        label={getSSOTogleLabel(subscriptionPlan)}
                        isDisabled={!hasSSOValidPlan(subscriptionPlan)}
                      />
                      {
                        isSSOEnabled && hasSSOValidPlan(subscriptionPlan) &&
                        <div className='sso-setup-link' {...accessibleOnClick(()=> handleGetSSOSetupUrl())}>
                          <span><LinkIcon height='20' width='20' /></span>
                          <span>SSO Setup Link</span>
                        </div>
                      }
                    </div>
                    {
                      isSSOEnabled && hasSSOValidPlan(subscriptionPlan) && organizationData &&
                      <DataTable
                        columns={getSSOLoginColumns()}
                        data={getSSOLoginData()}
                      />
                    }
                  </div>
                }
                <AdminSettingToggle
                  checked={isMFAEnabled}
                  onChange={() =>
                    onSettingToggle(
                      ShAccountSettingsCode.IsMFAEnabled,
                      !isMFAEnabled,
                    )
                  }
                  code={ShAccountSettingsCode.IsMFAEnabled}
                  isDisabled={isSSOEnabled}
                  label={
                    <span {...(isSSOEnabled && {className: 'mfa-toggle-label-disable'})}>
                      Require email OTP for all user logins as a Two-Factor Authentication step
                    </span>
                  }
                  tooltipText={isSSOEnabled && "2FA is disabled since you're using Login with SSO Method"}
                />
              </AdminSettingSection>
            }
          </div>
        )}
      </GlobalSettings.Content>

      <ConfirmationModalV3
        show={emailVerificationModal}
        title={getToggleOnOrOffMessage(isVerificationActive, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={getEmailVerificationModalContent(isVerificationActive, t)}
        onClose={onHideEmailVerificationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.IsVerificationActive,
            !isVerificationActive,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={prospectDuplicationModal}
        title={getToggleOnOrOffMessage(prospectDuplicationAllowed, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getProspectDuplicationAllowedModalContent(
          prospectDuplicationAllowed,
          t,
        )}
        onClose={onHideProspectDuplicationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.ProspectDuplicationAllowed,
            !prospectDuplicationAllowed,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={isProspectSharingConfirmationModalVisible}
        title={getProspectSharingOnOffMessage(prospectSharing, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getProspectSharingModalContent(prospectSharing)}
        onClose={onHideProspectSharingModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.ProspectSharing,
            !prospectSharing,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={autoAddToProspectOnLeadRevealConfirmationModalVisible}
        title={getAutoAddToProspectOnLeadRevealOnOffMessage(
          AutoAddToProspectOnLeadReveal,
          t,
        )}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getAutoAddToProspectOnLeadRevealModalContent(
          AutoAddToProspectOnLeadReveal,
          t,
        )}
        onClose={() => {
          setAutoAddToProspectOnLeadRevealConfirmationModalVisible(false);
        }}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.AutoAddToProspectOnLeadReveal,
            !AutoAddToProspectOnLeadReveal,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={showSSOEnableWarningModal}
        title="Are you Sure you want to enable login with SSO"
        hideTitleIcon={true}
        icon={<AlertCircle />}
        contents={[
          'By enabling SSO Login, all users in your organisation will no longer be able to login using passwords and all active session will be logged out. Everyone will be required to use Login with SSO method.',
          '',
          'Please proceed with caution.',
        ]}
        onClose={() => setShowSSOEnableWarningModal(false)}
        onSubmit={handleSSOEnableWarningSumbit}
        showCloseIcon={false}
        cancelButtonText='Cancel'
        submitButtonText='Proceed'
      />

      <ConfirmationModalV3
        show={showSSOSetUpModal}
        title="Setup SSO Login"
        hideTitleIcon={true}
        showCloseIcon={false}
        onClose={() => {
          setShowSSOSetUpModal(false);
          setIsSSODisplayNameError(false);
          setIsSSODomainError(false);
        }}
        onSubmit={handleSSOOrganizationCreation}
        cancelButtonText='Cancel'
        submitButtonText={organizationData ? 'Update' : 'Create'}
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        content={getSSOSetUpContent()}
      />

      <ConfirmationModalV3
        className='sso-setup-link-modal'
        show={showSSOSetupLinkModal}
        title="SSO Setup Link"
        hideTitleIcon={true}
        showCloseIcon={false}
        onClose={handleSSOSetupLinkModalonClose}
        onSubmit={() => {
          navigator.clipboard.writeText(ssoSetupUrl);
          toaster.success('Setup Link Copied to clipboard', {
            theme: Theme.New,
            delay: 3000
          });
        }}
        cancelButtonText='Close'
        submitButtonText='Copy'
        content={getSSOSetupLinkContent()}
      />
    </>
  );
};

export default AdminSettings;
