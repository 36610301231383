import React from 'react';
import { AlertTriangle } from '@saleshandy/icons';
import { Images } from '../../../../../../../shared/app-constants';
import Alert from '../../../../../../../shared/design-system/components/alert';

const ZeroLimitErrorNudge = ({
  zeroLimitError,
  isBulkUpdatingEmailAccounts,
  maxLimit,
  t,
}) => {
  if (zeroLimitError && !isBulkUpdatingEmailAccounts) {
    return (
      <div className="row mt-2">
        <div className="col-md-10">
          <Alert
            contentContainer={<span>{t('messages.zero_limit_error')}</span>}
            variant={Alert.Variant.Danger}
            theme={Alert.Theme.New}
            svgIcon={Images.AlertTriangleRed}
          />
        </div>
      </div>
    );
  }

  if (zeroLimitError && isBulkUpdatingEmailAccounts) {
    return (
      <div className="bulk-update-email-error-wrapper">
        <div className="icon">
          <AlertTriangle />
        </div>
        <p>Sending limit should be min 1 and max {maxLimit || 4000}</p>
      </div>
    );
  }

  return <></>;
};

export default ZeroLimitErrorNudge;
