import React from 'react';
import { useTranslation } from 'react-i18next';
import errorDivContent from '../email-sending-limit-nudge-content';
import Alert from '../../../../../../../shared/design-system/components/alert';
import { Images } from '../../../../../../../shared/app-constants';

const HardLimitErrorNudge = ({
  hardLimitError,
  hardLimit,
  onConnectAnotherEmailAccount,
  emailServiceProvider,
}) => {
  const { t } = useTranslation();

  return (
    hardLimitError && (
      <div className="row mt-2">
        <div className="col-md-10">
          <Alert
            contentContainer={errorDivContent(
              hardLimit,
              onConnectAnotherEmailAccount,
              emailServiceProvider,
              t,
            )}
            variant={Alert.Variant.Danger}
            theme={Alert.Theme.New}
            svgIcon={Images.AlertTriangleRed}
          />
        </div>
      </div>
    )
  );
};

export default HardLimitErrorNudge;
