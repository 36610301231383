/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, ImageIcon } from '@saleshandy/design-system';
import {
  Building,
  Check,
  ChevronDown,
  ChevronUp,
  CircleCheck,
  Copy,
  FacebookBw,
  LinkedinBw,
  Mail,
  MapPin,
  PhoneSignal,
  Send,
  TwitterBw,
  UserList,
} from '@saleshandy/icons';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Angelist } from '../../../../shared/svg/angelist';
import { Stackoverflow } from '../../../../shared/svg/stackoverflow';
import { Github } from '../../../../shared/svg/github';
import { Quora } from '../../../../shared/svg/quora';
import { Me } from '../../../../shared/svg/me';
import { NogenderAvatar } from '../../../../shared/svg/no-gender-avatar';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import ViewMore from '../view-more';
import { Placement } from '../../../../shared/design-system/components/overlay';
import AddToSequenceModal from '../../../prospect/components/prospect-list/components/modals/add-to-sequence-modal';
import { DefaultCompanyLogo } from '../../../../shared/svg/default-company-logo';
import {
  leadAddToProspectAPI,
  leadAddToSequenceAPI,
  revealSingleLeadAPI,
} from '../../helpers/leads.api';
import toaster, { Theme } from '../../../../shared/toaster';
import { LeadFinderTabKey, LeadRevealType } from '../../type';
import { getPostLoadMetaRequest } from '../../../home/extra-actions';
import GetContactDetail from '../../../prospect/components/prospect-list/components/modals/get-contact-info-modal';
import { RootState } from '../../../../store/root-reducer';
import { LEAD_LISTING_MESSAGES } from '../../helpers/leads-message';
import SubscriptionStatus from '../../../settings/components/billing-subscription/enums/subscription-status';
import pauseLeadFinderError from '../../../../shared/utils/errors/pause-lead-finder-error';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import {
  getUserLeadFinderPlanName,
  getUserPlanName,
  getUserRole,
  getUserShAccountId,
  getUserTrackingId,
  leadFinderBulkOperationLimit,
} from '../../../../shared/utils/user-details';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import { SystemErrors } from '../../../../shared/enums/system-errors';
import { isFreePlan } from '../../../../shared/utils/plans';
import planPermissionError from '../../../../shared/utils/errors/plan-permission-error';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import {
  getCircleCheckIconClassName,
  getCircleCheckTooltip,
  getDomainsFromEmails,
} from '../../helpers/helper';
import LeadDomains from '../lead-domains';

export default function IndividualLeads({
  topPeopleLimit,
  leadCredits,
  leadSampleData,
  handleCheckUnCheckSingleLead,
  selectedLeads,
  handleAppendLead,
  isBulkRevealing,
  disabledLeads,
  deSelectedLeads,
  isSelectedAllLeads,
  viewShowProspectDetailsModal,
  activeTabKey,
  handleResetStateAfterAnyAction,
  leadsPaginationData,
  loadingGetInfoLeads,
  setLoadingGetInfoLeads,
  handleFetchLeadsAfterBulkActions,
  isFirstPollRequest,
  apiParam,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingAddToProspect, setLoadingAddToProspect] = useState<boolean>(
    false,
  );
  const leadFinderSubscriptionPlanStatus = useSelector(
    (state: RootState) => state.home?.leadFinderSubscription?.status,
  );
  const [revealLead, setRevealLead] = useState();
  const [isContactCopied, setIsContactCopied] = useState(false);
  const [showAddToSequenceModal, setShowAddToSequenceModal] = useState<boolean>(
    false,
  );
  const [expandedLeads, setExpandedLeads] = useState([]);
  const [hoveredLead, setHoveredLead] = useState(null);
  const [hoveredAddToProspectLead, setHoveredAddToProspectLead] = useState(
    null,
  );
  const [
    CreditNotAvailableModalOpen,
    setCreditNotAvailableModalOpen,
  ] = useState<boolean>(false);

  const [hoveredEmail, setHoveredEmail] = useState<string | null>(null);
  const [hoveredPhone, setHoveredPhone] = useState<string | null>(null);

  const hideAddToSequenceModal = () => {
    setShowAddToSequenceModal(false);
  };

  const hideCreditNotAvailableModal = () => {
    setCreditNotAvailableModalOpen(false);
  };

  const handleViewLess = (lead) => {
    setExpandedLeads((prevExpandedLeads) => {
      if (!prevExpandedLeads.includes(lead.id)) {
        return [...prevExpandedLeads, lead.id];
      }
      return prevExpandedLeads.filter((leadId) => leadId !== lead.id);
    });
  };

  const getTooltip = (text: string, exterClass?: string) => (
    <Tooltip id="popover-basic" className={`bs-tooltip-inner ${exterClass}`}>
      {text}
    </Tooltip>
  );

  const isLeadSelected = (lead, index) => {
    if (activeTabKey === LeadFinderTabKey.PEOPLE_TAB) {
      const totalLeadsSelectionLimit = leadsPaginationData.start + index + 1;
      return (
        (isSelectedAllLeads &&
          totalLeadsSelectionLimit <= topPeopleLimit &&
          !deSelectedLeads?.some((l) => l?.id === lead?.id)) ||
        selectedLeads?.some((l) => l?.id === lead?.id)
      );
    }

    return (
      (isSelectedAllLeads &&
        !deSelectedLeads?.some((l) => l?.id === lead?.id)) ||
      selectedLeads?.some((l) => l?.id === lead?.id)
    );
  };

  const onContactCopied = (contact) => {
    if (document.hasFocus()) {
      navigator.clipboard
        ?.writeText(contact)
        .then(() => {
          setIsContactCopied(true);
          setTimeout(() => setIsContactCopied(false), 2000);
        })
        .catch(() => {});
    }
  };

  const handleFetchLead = async (leadId) => {
    if (isFirstPollRequest) {
      handleFetchLeadsAfterBulkActions(true, true, [leadId]);
    }
  };

  const handleRevealLead = async (leadId: number) => {
    try {
      setLoadingGetInfoLeads((prev) => ({
        ...prev,
        [leadId]: true,
      }));
      handleResetStateAfterAnyAction([leadId]);
      const payload = {
        revealLeadId: leadId,
        leadsFilter: apiParam,
      };
      const singleRevealRes = await revealSingleLeadAPI(payload);
      if (singleRevealRes) {
        const { message, status } = singleRevealRes?.payload || {};
        if (status === 0) {
          toaster.error(message || SystemErrors.InternalServerError, {
            theme: Theme.New,
          });
        } else if (status === 2) {
          toaster.warning(
            message ||
              'You another lead reveal action is currently in progress. Please wait until it finishes.',
            { theme: Theme.New },
          );
        } else {
          toaster.success(message || '1 Lead will be revealed', {
            theme: Theme.New,
          });
        }
        handleFetchLead(leadId);
      }
    } catch (e: any) {
      setLoadingGetInfoLeads((prev) => ({
        ...prev,
        [leadId]: false,
      }));
      toaster.error(e?.message || SystemErrors.InternalServerError, {
        theme: Theme.New,
      });
    }
  };

  const addtoProspectHandle = async (prospectId) => {
    try {
      setLoadingAddToProspect(true);
      const addToProspectRes = await leadAddToProspectAPI(prospectId);
      if (addToProspectRes) {
        handleAppendLead(addToProspectRes?.payload);
        toaster.success(
          LEAD_LISTING_MESSAGES.add_to_prospect_single_lead_succeess_msg,
        );
      }
      dispatch(getPostLoadMetaRequest());

      setLoadingAddToProspect(false);
    } catch (e: any) {
      toaster.error(e?.message);
      setLoadingAddToProspect(false);
    }
  };

  const addContactToStep = async (sequenceId, stepId) => {
    try {
      const response = await leadAddToSequenceAPI(revealLead, {
        sequenceId,
        stepId,
      });
      if (response?.payload?.failed) {
        if (response?.payload?.failedReport?.length) {
          const failedReport = response?.payload?.failedReport?.[0];
          const errorMessage =
            failedReport?.reason ||
            'Prospect already exists in the selected step';
          toaster.error(errorMessage, {
            theme: Theme.New,
          });
        } else {
          toaster.error('Prospect already exists in the selected step', {
            theme: Theme.New,
          });
        }
      } else {
        toaster.success(
          LEAD_LISTING_MESSAGES.add_to_sequence_single_lead_succeess_msg,
        );
      }
      dispatch(getPostLoadMetaRequest());
    } catch (e: any) {
      toaster.error(e?.message);
    }
  };

  const upgradePlanhandler = () => {
    history.push({
      pathname: '/settings/billing/subscriptions',
    });
  };

  const checkPeopleTabLeadSelection = () =>
    isSelectedAllLeads && activeTabKey === LeadFinderTabKey.PEOPLE_TAB;

  return (
    <>
      {leadSampleData?.map((lead, index) => (
        <div
          key={lead?.id}
          className={`individual-leads ${
            expandedLeads?.includes(lead?.id) && 'leads-view-more'
          } ${index === leadSampleData?.length - 1 && 'leads-last-child'}`}
        >
          <div
            className={`lead-data d-flex align-items-center justify-content-between ${
              expandedLeads?.includes(lead?.id) && 'individual-leads-view-more'
            }`}
          >
            <div className="lead-user-details d-flex align-items-start justify-content-between">
              <div className="lead-part lead-user-social-container align-items-center">
                <span
                  onMouseEnter={() => setHoveredLead(lead?.id)}
                  onMouseLeave={() => setHoveredLead(null)}
                >
                  <OverlayTrigger
                    placement={Placement.Bottom}
                    overlay={getTooltip(
                      lead?.emails?.length === 0
                        ? 'Lead does not have any email address'
                        : LEAD_LISTING_MESSAGES.max_limit_reached_tooltip_msg,
                      'checkbox-tooltip',
                    )}
                    show={
                      hoveredLead === lead?.id &&
                      ((selectedLeads?.length >=
                        leadFinderBulkOperationLimit() &&
                        !selectedLeads?.some((l) => l?.id === lead?.id)) ||
                        disabledLeads?.some((l: any) => l?.id === lead?.id))
                    }
                  >
                    <Checkbox
                      disabled={
                        checkPeopleTabLeadSelection() ||
                        (selectedLeads?.length >=
                          leadFinderBulkOperationLimit() &&
                          !selectedLeads?.some((l) => l?.id === lead?.id)) ||
                        disabledLeads?.some((l: any) => l?.id === lead?.id)
                      }
                      className="checkbox-custome"
                      checked={isLeadSelected(lead, index)}
                      onChange={() => handleCheckUnCheckSingleLead(lead)}
                    />
                  </OverlayTrigger>
                </span>
                {lead?.isProspectCreated ? (
                  <OverlayTrigger
                    placement={Placement.Bottom}
                    overlay={getTooltip('View Prospect Details')}
                  >
                    <div
                      className="avatar-icon-container clickable"
                      onClick={() =>
                        viewShowProspectDetailsModal(lead?.contactId)
                      }
                    >
                      {lead?.profile_pic ? (
                        <ImageIcon
                          src={lead?.profile_pic}
                          alt={lead?.name}
                          height={50}
                          width={50}
                          className="avatar-icon"
                        />
                      ) : (
                        <div className="avatar-icon">
                          <NogenderAvatar />
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div className="avatar-icon-container">
                    {lead?.profile_pic ? (
                      <ImageIcon
                        src={lead?.profile_pic}
                        alt={lead?.name}
                        height={50}
                        width={50}
                        className="avatar-icon"
                      />
                    ) : (
                      <div className="avatar-icon">
                        <NogenderAvatar />
                      </div>
                    )}
                  </div>
                )}

                <div
                  className={`lead-info ${
                    lead?.isProspectCreated && 'clickable'
                  }`}
                  onClick={() => viewShowProspectDetailsModal(lead?.contactId)}
                >
                  {lead?.name && (
                    <span className="lead-name lh-16">{lead?.name ?? '-'}</span>
                  )}
                  {lead?.current_title && (
                    <span className="lead-designation lh-16">
                      {lead?.current_title ?? '-'}
                    </span>
                  )}
                  {lead?.location && (
                    <div className="d-flex align-items-start lead-location lh-16">
                      <MapPin width={16} height={16} className="map-icon" />
                      <span>{lead?.location ?? '-'}</span>
                    </div>
                  )}
                  {lead?.links && (
                    <span className="social-media-icon lh-16">
                      {lead?.links?.facebook && (
                        <a
                          href={lead?.links?.facebook}
                          target="_blank"
                          rel="noreferrer"
                          className={`${!lead?.isRevealed ? 'disabled' : ''} `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FacebookBw className="facebook-icon" />
                        </a>
                      )}
                      {lead?.links?.linkedin && (
                        <a
                          href={lead?.links?.linkedin}
                          target="_blank"
                          rel="noreferrer"
                          className={`${!lead?.isRevealed ? 'disabled' : ''} `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <LinkedinBw className="linkedin-icon" />
                        </a>
                      )}
                      {lead?.links?.twitter && (
                        <a
                          href={lead?.links?.twitter}
                          target="_blank"
                          rel="noreferrer"
                          className={`${!lead?.isRevealed ? 'disabled' : ''} `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <TwitterBw className="twitter-icon" />
                        </a>
                      )}
                      {lead?.links?.github && (
                        <a
                          href={lead?.links?.github}
                          target="_blank"
                          rel="noreferrer"
                          className={`github-icon ${
                            !lead?.isRevealed ? 'disabled' : ''
                          } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Github />
                        </a>
                      )}
                      {lead?.links?.quora && (
                        <a
                          href={lead?.links?.quora}
                          target="_blank"
                          rel="noreferrer"
                          className={`quora-icon ${
                            !lead?.isRevealed ? 'disabled' : ''
                          } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Quora />
                        </a>
                      )}
                      {lead?.links?.stackoverflow && (
                        <a
                          href={lead?.links?.stackoverflow}
                          target="_blank"
                          rel="noreferrer"
                          className={`stackoverflow-icon ${
                            !lead?.isRevealed ? 'disabled' : ''
                          } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Stackoverflow />
                        </a>
                      )}
                      {lead?.links?.aboutme && (
                        <a
                          href={lead?.links?.aboutme}
                          target="_blank"
                          rel="noreferrer"
                          className={`me-icon ${
                            !lead?.isRevealed ? 'disabled' : ''
                          } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Me />
                        </a>
                      )}
                      {lead?.links?.angelist && (
                        <a
                          href={lead?.links?.angelist}
                          target="_blank"
                          rel="noreferrer"
                          className={`angelist-icon ${
                            !lead?.isRevealed ? 'disabled' : ''
                          } `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Angelist />
                        </a>
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="lead-part lead-company-name-container align-items-center">
                <div className="lead-info">
                  {lead?.current_employer && (
                    <div className="lead-company-details company-details-container">
                      <div className="icon">
                        <DefaultCompanyLogo />
                      </div>
                      <span className="lead-company-name">
                        {lead?.current_employer}
                      </span>
                    </div>
                  )}
                  {lead?.job_history && lead?.job_history[0]?.department && (
                    <div className="lead-company-details lead-company-skill">
                      <div className="icon">
                        <Building className="icon-color" />
                      </div>
                      <span className="lead-company-name">
                        {lead?.job_history[0]?.department}
                      </span>
                    </div>
                  )}
                  {/* <div className="lead-company-details lead-company-user-total">
                  <div className="icon">
                    <Users className="icon-color" />
                  </div>
                  <span className="lead-company-name">17.2K</span>
                </div> */}
                  {lead?.location && (
                    <div className="lead-company-details lead-company-map">
                      <div className="icon">
                        <MapPin className="icon-color" />
                      </div>
                      <span className="lead-company-name">
                        {' '}
                        {lead?.location ?? '-'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="lead-part lead-phone-email-container">
                <div className="lead-info">
                  {lead?.phones?.length > 0 && (
                    <div className="lead-company-details lead-company-user-number">
                      {lead.phones.slice(0, 1).map((phoneObj, phoneIndex) => (
                        <div
                          key={`phone-${
                            phoneObj?.id || phoneObj?.number
                          }-${phoneIndex}`}
                          className="phone-row"
                          onMouseEnter={() =>
                            setHoveredPhone(phoneObj?.number || null)
                          }
                          onMouseLeave={() => setHoveredPhone(null)}
                        >
                          <div className="icon">
                            <PhoneSignal className="icon-color" />
                          </div>
                          <span className="user-number">
                            {phoneObj?.number || ''}
                          </span>
                          {hoveredPhone === phoneObj?.number &&
                            expandedLeads.includes(lead?.id) && (
                              <OverlayTrigger
                                placement={Placement.Bottom}
                                overlay={getTooltip(
                                  !isContactCopied ? 'Copy' : 'Copied',
                                )}
                              >
                                <Copy
                                  className="icon-color copy-icon"
                                  onClick={() => {
                                    onContactCopied(phoneObj?.number);
                                  }}
                                />
                              </OverlayTrigger>
                            )}
                          {lead?.phones?.length > 1 &&
                            !expandedLeads.includes(lead?.id) && (
                              <span
                                className="more-account"
                                onClick={() => handleViewLess(lead)}
                              >
                                +{lead?.phones?.length - 1} more
                              </span>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                  {(!lead?.isRevealed ||
                    lead?.revealType === LeadRevealType.EMAIL) &&
                    lead?.teaser?.phones?.length > 0 && (
                      <div className="lead-company-details lead-company-user-number-people-tab">
                        <div className="icon">
                          <PhoneSignal className="icon-color" />
                        </div>
                        <span className="user-number">
                          {lead?.teaser?.phones[0]?.number?.slice(0, 3)}
                          <span className="list-dots">
                            &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
                          </span>
                        </span>
                      </div>
                    )}
                  {lead?.emails?.length > 0 && (
                    <div
                      className={`lead-company-details lead-company-email ${
                        lead?.phones?.length === 0 ? 'mt-0' : ''
                      }`}
                    >
                      {lead.emails.slice(0, 1).map((emailObj, emailIndex) => (
                        <div
                          key={`email-${
                            emailObj?.id || emailObj?.email
                          }-${emailIndex}`}
                          className="email-row"
                          onMouseEnter={() =>
                            setHoveredEmail(emailObj?.email || null)
                          }
                          onMouseLeave={() => setHoveredEmail(null)}
                        >
                          <div className="icon">
                            <Mail className="icon-color mr-1" />
                          </div>
                          <div className="icon">
                            <OverlayTrigger
                              placement={Placement.Bottom}
                              overlay={
                                <Tooltip
                                  id="popover-basic"
                                  className="bs-tooltip-inner"
                                >
                                  {getCircleCheckTooltip(
                                    lead?.emails[0]?.grade,
                                  )}
                                </Tooltip>
                              }
                            >
                              <CircleCheck
                                className={`icon-color cursor-pointer mr-1 ${getCircleCheckIconClassName(
                                  lead?.emails[0]?.grade,
                                )}`}
                              />
                            </OverlayTrigger>
                          </div>
                          <span className="user-email">
                            {emailObj?.email || ''}
                          </span>
                          {hoveredEmail === emailObj?.email &&
                            expandedLeads.includes(lead?.id) && (
                              <OverlayTrigger
                                placement={Placement.Bottom}
                                overlay={getTooltip(
                                  !isContactCopied ? 'Copy' : 'Copied',
                                )}
                              >
                                <Copy
                                  className="icon-color-lead copy-icon-lead"
                                  onClick={() => {
                                    onContactCopied(emailObj?.email);
                                  }}
                                />
                              </OverlayTrigger>
                            )}
                          {lead?.emails?.length > 1 &&
                            !expandedLeads.includes(lead?.id) && (
                              <span
                                className="more-account"
                                onClick={() => handleViewLess(lead)}
                              >
                                +{lead?.emails?.length - 1} more
                              </span>
                            )}
                        </div>
                      ))}
                    </div>
                  )}

                  {!lead?.isRevealed &&
                    lead?.teaser?.emails?.length > 0 &&
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    lead?.teaser?.emails?.slice(0, 2).map((e: any, index) => (
                      <div
                        className={`lead-company-details lead-company-email-people-tab ${
                          lead?.teaser?.phones?.length === 0 && index === 0
                            ? 'mt-0'
                            : ''
                        }`}
                        key={`${lead?.id}-teaser-email-${index}`}
                      >
                        <div className="icon">
                          <Mail className="icon-color mr-1" />
                        </div>
                        <span className="user-email">
                          {' '}
                          <span className="list-dots">
                            &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
                          </span>
                          @{e}
                        </span>
                      </div>
                    ))}

                  <LeadDomains
                    lead={lead}
                    expandedLeads={expandedLeads}
                    handleViewLess={handleViewLess}
                  />

                  {!lead?.isRevealed &&
                    lead?.teaser?.emails?.length === 0 &&
                    lead?.teaser?.phones?.length === 0 && (
                      <div
                        className={`lead-company-details lead-company-email-people-tab ${
                          lead?.teaser?.phones?.length === 0 ? 'mt-0' : ''
                        }`}
                      >
                        <div className="icon">
                          <Mail className="icon-color mr-1" />
                        </div>
                        <span className="user-email">
                          Click Get Contact Info to view data
                        </span>
                      </div>
                    )}
                  {lead?.isCreditRefunded &&
                    !expandedLeads?.includes(lead?.id) && (
                      <OverlayTrigger
                        placement={Placement.Bottom}
                        overlay={getTooltip(
                          'The lead finder credit was refunded as we could not find any valid email address upon verification.',
                          'credit-refunded-tooltip',
                        )}
                      >
                        <div
                          className={`lead-company-details lead-credit-refunded ${
                            getDomainsFromEmails(
                              lead?.emails,
                              lead?.current_employer_domain,
                            ).length === 0
                              ? 'mt-0'
                              : ''
                          }`}
                          key={`${lead?.id}-credit-refunded-${index}`}
                        >
                          Credit refunded
                        </div>
                      </OverlayTrigger>
                    )}
                </div>
              </div>
            </div>
            <div className="lead-part align-items-center justify-content-end">
              {lead?.isRevealed && !lead?.reReveal ? (
                <div className="d-flex">
                  <span
                    onMouseEnter={() => setHoveredAddToProspectLead(lead?.id)}
                    onMouseLeave={() => setHoveredAddToProspectLead(null)}
                  >
                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={getTooltip(
                        'Lead does not have any email address',
                      )}
                      show={
                        hoveredAddToProspectLead === lead?.id &&
                        lead?.emails?.length === 0
                      }
                    >
                      <Button
                        variant="primary"
                        className={`prospect-btn ${
                          lead?.isProspectCreated ? 'prospect-saved-btn' : ''
                        }`}
                        isLoading={
                          loadingAddToProspect && lead?.id === revealLead
                        }
                        disabled={
                          lead?.isProspectCreated || lead?.emails?.length === 0
                        }
                        onClick={() => {
                          if (
                            hasResource(ResourceIdentifiers.CONTACTS_IMPORT) &&
                            !loadingAddToProspect
                          ) {
                            setRevealLead(lead?.id);
                            addtoProspectHandle(lead?.id);
                          } else if (isFreePlan()) {
                            const title =
                              'Please upgrade your plan to start adding prospects';
                            const content = [
                              'Your account is downgraded to a free plan. Upgrade to paid plan to add prospects and resume email sending.',
                            ];

                            planPermissionError.showModal(title, content, true);
                          }
                        }}
                      >
                        {lead?.isProspectCreated ? (
                          <Check className="mr-2" />
                        ) : (
                          <UserList className="mr-2" />
                        )}

                        <span className="il-button">
                          {lead?.isProspectCreated
                            ? 'Prospect Saved'
                            : 'Save as Prospect'}
                        </span>
                      </Button>
                    </OverlayTrigger>
                  </span>

                  <OverlayTrigger
                    placement={Placement.Bottom}
                    overlay={getTooltip(
                      lead?.emails?.length === 0
                        ? 'Lead does not have any email address'
                        : LEAD_LISTING_MESSAGES.add_to_sequence_tooltip_msg,
                    )}
                  >
                    <Button
                      variant="secondary"
                      className="ml-2 send-btn lead-reveal-send-btn"
                      disabled={lead?.emails?.length === 0}
                      onClick={() => {
                        if (
                          isFreePlan() &&
                          !hasPermission(Permissions.SEQUENCE_PROSPECT_WRITE)
                        ) {
                          const title =
                            'Please upgrade your plan to start adding prospects';
                          const content = [
                            'Your account is downgraded to a free plan. Upgrade to paid plan to add prospects and resume email sending.',
                          ];

                          planPermissionError.showModal(title, content, true);
                          return;
                        }
                        setRevealLead(lead?.id);
                        setShowAddToSequenceModal(true);
                      }}
                    >
                      <Send />
                    </Button>
                  </OverlayTrigger>
                </div>
              ) : (
                <div className="lead-info">
                  <Button
                    variant="secondary"
                    className="il-button get-contact-info"
                    disabled={
                      loadingGetInfoLeads[lead?.id] ||
                      isBulkRevealing ||
                      lead?.isRevealing
                    }
                    isLoading={
                      loadingGetInfoLeads[lead?.id] ||
                      isBulkRevealing ||
                      lead?.isRevealing
                    }
                    loadingText="Revealing Lead"
                    onClick={() => {
                      if (
                        leadFinderSubscriptionPlanStatus ===
                        SubscriptionStatus.Paused
                      ) {
                        pauseLeadFinderError.showModal();
                      } else if (Number(leadCredits) > 0) {
                        setRevealLead(lead?.id);
                        handleRevealLead(lead?.id);
                      } else {
                        setCreditNotAvailableModalOpen(true);
                        // Lead Finder Tripped Feature Event
                        window.analytics?.track?.({
                          userId: getUserTrackingId(),
                          event: AnalyticsEvents.TrippedFeature,
                          properties: {
                            shAccountId: getUserShAccountId(),
                            current_plan: getUserPlanName(),
                            role: getUserRole(),
                            'LF Plan': getUserLeadFinderPlanName(),
                            feature_type: 'Lead Finder',
                          },
                        });
                      }
                    }}
                  >
                    Get Contact Info
                  </Button>
                </div>
              )}
            </div>
          </div>
          {lead?.isRevealed && (
            <div>
              <Button
                className="view-button mb-0"
                onClick={() => handleViewLess(lead)}
                role="button"
              >
                {expandedLeads.includes(lead?.id) ? (
                  <>
                    View Less <ChevronUp />
                  </>
                ) : (
                  <>
                    View More <ChevronDown />
                  </>
                )}
              </Button>
            </div>
          )}
          {expandedLeads.includes(lead?.id) && <ViewMore lead={lead} />}
        </div>
      ))}

      {showAddToSequenceModal && (
        <AddToSequenceModal
          show={showAddToSequenceModal}
          onHide={hideAddToSequenceModal}
          addToSequence={addContactToStep}
          isSubscribed={true}
        />
      )}

      {CreditNotAvailableModalOpen && (
        <GetContactDetail
          show={CreditNotAvailableModalOpen}
          onHide={hideCreditNotAvailableModal}
          onDelete={upgradePlanhandler}
          title="Buy credits to see contact information"
          icon={<UserList width={18} height={18} className="orange-txt-14" />}
          content={
            <>
              <div className="mt-2">
                <span className="regular-2">
                  You don't have enough lead finder credits to view contact
                  information for selected leads.
                </span>
              </div>
              <div className="second-line-get-contact-modal">
                <span className="regular-2">
                  Please add more credits or upgrade your plan to continue.
                </span>
              </div>
            </>
          }
          submitButtonText="Upgrade"
        />
      )}
    </>
  );
}
