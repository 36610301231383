export enum SortByKey {
  SentAt = 'sentAt',
  Opened = 'opened',
  Clicked = 'clicked',
  LastActivity = 'lastActivity',
}

export enum RecipientType {
  to = 'to',
  cc = 'cc',
  bcc = 'bcc',
}

export enum UnifiedInboxFeed {
  All = 'all',
  Unread = 'unread',
  Scheduled = 'scheduled',
  Draft = 'draft',
}

export enum PaginationOption {
  PAGE = 'page',
  SORT_BY_KEY = 'sortByKey',
  ORDER = 'order',
  SEARCH = 'search',
  OWNERS = 'mailbox',
}

export enum MailSentimentTypes {
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral',
  Uncategorized = 'Uncategorized',
}

export enum MailCategories {
  Uncategorized = '🏷️ Uncategorized',
  Interested = '👍 Interested',
  Not_Interested = '👎 Not Interested',
  Meeting_Booked = '📅 Meeting Booked',
  Out_Of_Office = '📩️ Out of Office',
  Closed = '🎉 Closed',
  Not_Now = '🚫 Not now',
  Do_Not_Contact = '📵 Do Not Contact',
}

export enum UnifiedInboxTrippedFeatures {
  Filters = 'filters',
  Reply = 'reply',
  Forward = 'forward',
  ReplyAll = 'replyAll',
}

export const SELECT_ALL_EMAIL_THREADS_LIMIT = 10000;

export enum CategoryUpdatedType {
  System = 'system',
  Manual = 'manual',
}
