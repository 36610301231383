import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
// eslint-disable-next-line import/no-cycle
import BillingSubscription from './billing-subscription';
import {
  getAccountSubscriptionRequest,
  getPlansRequest,
  getInvoiceURLRequest,
  purchaseSubscriptionRequest,
  getConnectedUsersAndEmailAccountsRequest,
  applyCouponCodeRequest,
  getEmailVerificationCreditsRequest,
  getEmailVerificationPlanRequest,
  purchaseEmailVerificationCreditsRequest,
  modifySubscriptionRequest,
  purchaseModifySubscriptionRequest,
  purchaseLeadFinderModifySubscriptionRequest,
  reactivateSubscriptionRequest,
  reactivateLeadFinderSubscriptionRequest,
  resumeSubscriptionRequest,
  resumeLeadFinderSubscriptionRequest,
  handleSubscriptionRequest,
  handleLeadFinderChurnkeySubscriptionRequest,
  getLeadFinderSubscriptionRequest,
  getAccountSubscriptionPlanRequest,
  getPurchaseHistoryRequest,
  exportCreditHistoryRequest,
} from './extra-actions';
import {
  resetSubscription,
  deleteAppliedCouponCode,
  resetPurchaseEmailVerificationCreditsResponse,
  resetModifySubscriptionResponse,
  resetReactivateSubscriptionResponse,
  resetReactivateLeadFinderSubscriptionResponse,
  resetResumeSubscriptionResponse,
  resetResumeLeadFinderSubscriptionResponse,
  resetHandleSubscriptionResponse,
  resetHandleLeadFinderSubscriptionChurnkeyResponse,
  resetInvoice,
  resetExportCreditHistoryRequest,
} from './billing-subscription-slice';
import { getUserSettingsRequest } from '../../../home/extra-actions';
import {
  ApplyCouponCodeRequest,
  PurchaseEmailVerificationCreditsRequest,
  ModifySubscriptionRequest,
  PurchaseModifySubscriptionRequest,
  ReactivateResumeSubscriptionRequest,
  HandleSubscriptionRequest,
  PurchaseSubscriptionRequest,
} from './types';
import { ExportCreditHistoryPayload } from './types/purchase-history';

const mapStateToProps = (state: RootState) => ({
  sendGetAccountSubscriptionRequestStatus:
    state.billingAndSubscription.getAccountSubscriptionRequest.status,
  sendGetAccountSubscriptionPlanRequestStatus:
    state.billingAndSubscription.getAccountSubscriptionPlanRequest.status,
  sendGetAccountSubscriptionRequestError:
    state.billingAndSubscription.getAccountSubscriptionRequest.error,
  sendGetAccountSubscriptionPlanRequestError:
    state.billingAndSubscription.getAccountSubscriptionPlanRequest.error,
  sendGetPlansRequestStatus:
    state.billingAndSubscription.getPlansRequest.status,
  sendGetPlansRequestError: state.billingAndSubscription.getPlansRequest.error,
  sendGetInvoiceURLRequestStatus:
    state.billingAndSubscription.getInvoiceURLRequest.status,
  sendGetInvoiceURLRequestError:
    state.billingAndSubscription.getInvoiceURLRequest.error,
  sendPurchaseSubscriptionRequestStatus:
    state.billingAndSubscription.purchaseSubscriptionRequest.status,
  sendPurchaseSubscriptionRequestError:
    state.billingAndSubscription.purchaseSubscriptionRequest.error,
  sendPurchaseSubscriptionRequestMessage:
    state.billingAndSubscription.purchaseSubscriptionRequest.message,
  sendGetAccountSubscriptionResponse:
    state.billingAndSubscription.getAccountSubscriptionResponse,
  sendGetAccountSubscriptionPlanResponse:
    state.billingAndSubscription.getAccountSubscriptionPlanResponse,
  sendGetLeadFinderSubscriptionResponse:
    state.billingAndSubscription.getLeadFinderSubscriptionResponse,
  sendGetConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  sendGetConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
  sendGetConnectedUsersAndEmailAccountsRequestResponse:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse,
  sendApplyCouponCodeRequestStatus:
    state.billingAndSubscription.applyCouponCodeRequest.status,
  sendApplyCouponCodeRequestMessage:
    state.billingAndSubscription.applyCouponCodeRequest.message,
  sendApplyCouponCodeRequestError:
    state.billingAndSubscription.applyCouponCodeRequest.error,
  getCouponCodeResponse: state.billingAndSubscription.applyCouponCodeResponse,
  sendGetPlansResponse: state.billingAndSubscription.getPlansResponse,
  getInvoiceResponse: state.billingAndSubscription.getInvoiceResponse,
  customerId: state.home.subscription?.customerId,
  leadFinderCustomerId: state.home.leadFinderSubscription?.customerId,
  subscriptionId: state.home.subscription?.subscriptionId,
  leadFinderSubscriptionId: state.home.leadFinderSubscription?.subscriptionId,
  isPaymentActionRequired: state.home.subscription?.paymentActionRequired,
  sendGetEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.getEmailVerificationCreditsRequest.status,
  sendGetEmailVerificationCreditsRequestError:
    state.billingAndSubscription.getEmailVerificationCreditsRequest.error,
  sendGetEmailVerificationPlansRequestStatus:
    state.billingAndSubscription.getEmailVerificationPlanRequest.status,
  sendGetEmailVerificationPlansRequestError:
    state.billingAndSubscription.getEmailVerificationPlanRequest.error,
  sendPurchaseEmailVerificationCreditsRequestStatus:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.status,
  sendPurchaseEmailVerificationCreditsRequestError:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest.error,
  sendPurchaseEmailVerificationCreditsRequestMessage:
    state.billingAndSubscription.purchaseEmailVerificationCreditsRequest
      .message,
  getPurchaseEmailVerificationCreditsResponse:
    state.billingAndSubscription.purchaseEmailVerificationCreditsResponse,
  getEmailVerificationCreditsResponse:
    state.billingAndSubscription.getEmailVerificationCreditsResponse,
  getEmailVerificationPlanResponse:
    state.billingAndSubscription.getEmailVerificationPlanResponse,
  sendGetModifySubscriptionRequestStatus:
    state.billingAndSubscription.modifySubscriptionRequest.status,
  sendGetModifySubscriptionRequestError:
    state.billingAndSubscription.modifySubscriptionRequest.error,
  sendGetModifySubscriptionResponse:
    state.billingAndSubscription.modifySubscriptionResponse,
  sendGetPurchaseModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseModifySubscriptionRequest.status,
  sendPurchaseLeadFinderModifySubscriptionRequestStatus:
    state.billingAndSubscription.purchaseLeadFinderModifySubscriptionRequest
      .status,
  sendGetReactivateSubscriptionRequestStatus:
    state.billingAndSubscription.reactivateSubscriptionRequest.status,
  sendGetReactivateSubscriptionRequestError:
    state.billingAndSubscription.reactivateSubscriptionRequest.error,
  sendGetReactivateLeadFinderSubscriptionStatus:
    state.billingAndSubscription.reactivateLeadFinderSubscriptionRequest.status,
  sendGetReactivateLeadFinderSubscriptionError:
    state.billingAndSubscription.reactivateLeadFinderSubscriptionRequest.error,
  sendGetReactivateSubscriptionResponse:
    state.billingAndSubscription.reactivateSubscriptionResponse,
  sendGetReactivateLeadFinderSubscriptionResponse:
    state.billingAndSubscription.reactivateLeadFinderSubscriptionResponse,
  sendGetResumeSubscriptionRequestStatus:
    state.billingAndSubscription.resumeSubscriptionRequest.status,
  sendGetResumeSubscriptionRequestError:
    state.billingAndSubscription.resumeSubscriptionRequest.error,
  sendGetResumeLeadFinderSubscriptionStatus:
    state.billingAndSubscription.resumeLeadFinderSubscriptionRequest.status,
  sendGetResumeLeadFinderSubscriptionError:
    state.billingAndSubscription.resumeLeadFinderSubscriptionRequest.error,
  sendGetResumeSubscriptionResponse:
    state.billingAndSubscription.resumeSubscriptionResponse,
  sendGetResumeLeadFinderSubscriptionResponse:
    state.billingAndSubscription.resumeLeadFinderSubscriptionResponse,
  sendGetHandleSubscriptionResponse:
    state.billingAndSubscription.handleSubscriptionResponse,
  sendGetHandleSubscriptionRequestError:
    state.billingAndSubscription.handleSubscriptionRequest.error,
  sendGetHandleSubscriptionRequestStatus:
    state.billingAndSubscription.handleSubscriptionRequest.status,
  sendGetHandleLeadFinderChurnkeySubscriptionError:
    state.billingAndSubscription.handleLeadFinderChurnkeySubscriptionRequest
      .error,
  sendGetHandleLeadFinderChurnkeySubscriptionStatus:
    state.billingAndSubscription.handleLeadFinderChurnkeySubscriptionRequest
      .status,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  leadFinderPlanTransitionDetails:
    state.home.leadFinderSubscription?.planTransitionDetails,
  churnkeyHash: state.home.churnkeyHash,
  leadFinderChurnkeyHash: state.home.leadFinderChurnkeyHash,
  subscriptionPlanStatus: state.home.subscription?.status,
  leadFinderSubscriptionPlanStatus: state.home?.leadFinderSubscription?.status,
  pauseEndsAt: state.home.subscription?.pauseEndsAt,
  leadFinderPauseEndsAt: state.home.leadFinderSubscription?.pauseEndsAt,
  pausedAt: state.home.subscription?.pausedAt,
  leadFinderpausedAt: state.home.leadFinderSubscription?.pausedAt,
  ltdExpiryDate: state.home.agencyConfig?.ltdExpiryDate,
  planCode: state.home?.subscription?.planCode,
  leadFinderPlanCode: state.home?.leadFinderSubscription?.planCode,
  planType: state.home?.subscription?.planType,
  leadFinderPlanType: state.home?.leadFinderSubscription?.planType,
  currentPlanName: state.home?.subscription?.planName,
  purchaseHistory: state.billingAndSubscription.purchaseHistory,
  getPurchaseHistoryRequestStatus:
    state.billingAndSubscription.getPurchaseHistoryRequest.status,
  hasDomains: state.home.hasDomains,
  exportCreditHistoryRequestStatus:
    state.billingAndSubscription.exportCreditHistoryRequest.status,
  exportCreditHistoryRequestMessage:
    state.billingAndSubscription.exportCreditHistoryRequest.message,
    timeZone: state.myProfile.myProfile.timeZone,
});

const mapDispatchToProps = {
  sendGetAccountSubscriptionRequest: () => getAccountSubscriptionRequest(),
  sendGetAccountSubscriptionPlanRequest: (id: string) =>
    getAccountSubscriptionPlanRequest(id),
  sendGetLeadFinderSubscriptionRequest: () =>
    getLeadFinderSubscriptionRequest(),
  sendGetPlansRequest: () => getPlansRequest(),
  sendGetInvoiceURLRequest: (customerId: string) =>
    getInvoiceURLRequest({ customerId }),
  sendPurchaseSubscriptionRequest: (payload: PurchaseSubscriptionRequest) =>
    purchaseSubscriptionRequest(payload),
  resetSubscription: () => resetSubscription(),
  resetPurchaseEmailVerificationCreditsResponse: () =>
    resetPurchaseEmailVerificationCreditsResponse(),
  resetReactivateSubscriptionResponse: () =>
    resetReactivateSubscriptionResponse(),
  resetReactivateLeadFinderSubscriptionResponse: () =>
    resetReactivateLeadFinderSubscriptionResponse(),
  resetResumeSubscriptionResponse: () => resetResumeSubscriptionResponse(),
  resetResumeLeadFinderSubscriptionResponse: () =>
    resetResumeLeadFinderSubscriptionResponse(),
  resetHandleSubscriptionResponse: () => resetHandleSubscriptionResponse(),
  resetHandleLeadFinderSubscriptionChurnkeyResponse: () =>
    resetHandleLeadFinderSubscriptionChurnkeyResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  sendApplyCouponCodeRequest: (payload: ApplyCouponCodeRequest) =>
    applyCouponCodeRequest(payload),
  deleteAppliedCouponCode: () => deleteAppliedCouponCode(),
  sendGetEmailVerificationCreditsRequest: () =>
    getEmailVerificationCreditsRequest(),
  sendGetEmailVerificationPlansRequest: () => getEmailVerificationPlanRequest(),
  sendPurchaseEmailVerificationCreditsRequest: (
    payload: PurchaseEmailVerificationCreditsRequest,
  ) => purchaseEmailVerificationCreditsRequest(payload),
  sendModifySubscriptionRequest: (payload: ModifySubscriptionRequest) =>
    modifySubscriptionRequest(payload),
  sendPurchaseModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseModifySubscriptionRequest(payload),
  sendPurchaseLeadFinderModifySubscriptionRequest: (
    payload: PurchaseModifySubscriptionRequest,
  ) => purchaseLeadFinderModifySubscriptionRequest(payload),
  sendReactivateSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateSubscriptionRequest(payload),
  sendReactivateLeadFinderSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateLeadFinderSubscriptionRequest(payload),
  sendResumeSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => resumeSubscriptionRequest(payload),
  sendResumeLeadFinderSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => resumeLeadFinderSubscriptionRequest(payload),
  sendHandleSubscriptionRequest: (payload: HandleSubscriptionRequest) =>
    handleSubscriptionRequest(payload),
  sendHandleLeadFinderChurnkeySubscriptionRequest: (
    payload: HandleSubscriptionRequest,
  ) => handleLeadFinderChurnkeySubscriptionRequest(payload),
  resetModifySubscriptionResponse: () => resetModifySubscriptionResponse(),
  resetInvoice: () => resetInvoice(),
  hideLoading: () => hideLoading(),
  showLoading: () => showLoading(),
  sendPurchaseHistoryRequest: () => getPurchaseHistoryRequest(),
  sendExportCreditHistoryRequest: (payload: ExportCreditHistoryPayload) =>
    exportCreditHistoryRequest(payload),
  resetExportCreditHistoryRequest: () => resetExportCreditHistoryRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export type LocationStateType = {
  isModifySubscription: boolean;
};

export default connector(BillingSubscription);
