/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-case */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import {
  AlertCircle,
  Diamond,
  Download,
  Send,
  UserList,
} from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import { useHistory, useLocation } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';
import AddToSequenceModal from '../../../prospect/components/prospect-list/components/modals/add-to-sequence-modal';
import GetContactDetail from '../../../prospect/components/prospect-list/components/modals/get-contact-info-modal';
import {
  bulkAddToProspectLeadAPI,
  bulkAddToSequenceLeadAPI,
  bulkExportLeadAPI,
  bulkPeopleRevealLeadAPI,
  bulkRevealLeadAPI,
  bulkSavedAddToProspectLeadAPI,
  bulkSavedAddToSequenceLeadAPI,
  exportAllLeadsAPI,
} from '../../helpers/leads.api';
import toaster, { Theme } from '../../../../shared/toaster';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import {
  BULK_ACTION_TIMEOUT,
  BulkLeadsActionType,
  LeadFinderTabKey,
} from '../../type';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { LEAD_LISTING_MESSAGES } from '../../helpers/leads-message';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import {
  getUserLeadFinderPlanName,
  getUserPlanName,
  getUserRole,
  getUserShAccountId,
  getUserTrackingId,
  leadFinderBulkOperationLimit,
} from '../../../../shared/utils/user-details';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import { SystemErrors } from '../../../../shared/enums/system-errors';
import TagAutosuggest, {
  RenderMode,
} from '../../../../shared/design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import AddRemoveTagButton from '../add-remove-tag-button/add-remove-tag-button';
import planPermissionError from '../../../../shared/utils/errors/plan-permission-error';
import { isFreePlan } from '../../../../shared/utils/plans';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { enableBulkActionRequestPolling } from '../../../home/home-slice';

export default function TotalCredits({
  topPeopleLimit,
  handleSelectAllLead,
  selectedLeads,
  leadsData,
  isBulkRevealing,
  apiParam,
  activeTabKey,
  setIsBulkRevealing,
  setSelectedLeads,
  isSelectAllIndeterminate,
  disabledLeads,
  selectedAllLeadsCount,
  leadCredits,
  isSelectedAllLeads,
  setIsSelectedAllLeads,
  handleClearSelectedAllBulkAction,
  handleSelectAllBulkActionLead,
  deSelectedLeads,
  leadTags,
  fetchLeadTags,
  handleResetStateAfterAnyAction,
  savedFilters,
  setSavedFilters,
  handleFetchLeadsAfterBulkActions,
  setLoadingGetInfoLeads,
  loadingGetInfoLeads,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const [bulkTimeout, setBulkTimeout] = useState(null);

  useEffect(() => {
    if (isSelectedAllLeads) {
      handleSelectAllLead(leadsData.profiles);
    }
  }, []);

  const [hoveredSelectAll, setHoveredSelectAll] = useState(false);

  const [loadingBulkAction, setLoadingBulkAction] = useState({
    loadingReveal: false,
    loadingAddToProspect: false,
    loadingExport: false,
    loadingAddToSequence: false,
    loadingAddTag: false,
  });
  const [showAddToSequenceModal, setShowAddToSequenceModal] = useState<boolean>(
    false,
  );
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [
    CreditNotAvailableModalOpen,
    setCreditNotAvailableModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedMoreThanLimitModalOpen,
    setSelectedMoreThanLimitModalOpen,
  ] = useState(false);

  const [
    getContactInfoModalOpen,
    setGetContactInfoModalOpen,
  ] = useState<boolean>(false);
  const hideAddToSequenceModal = () => {
    setShowAddToSequenceModal(false);
  };

  const hideGetContactInfoModal = () => {
    setGetContactInfoModalOpen(false);
  };

  const hideLimitreachedModal = () => {
    setSelectedMoreThanLimitModalOpen(false);
  };

  const hideCreditNotAvailableModal = () => {
    setCreditNotAvailableModalOpen(false);
  };

  const isLeadNoRevealedPresent = selectedLeads?.some(
    (l) => !l?.isRevealed || l?.reReveal,
  );

  const shouldDisplayGetContactInfo = () =>
    isLeadNoRevealedPresent ||
    (isSelectedAllLeads && activeTabKey === LeadFinderTabKey.PEOPLE_TAB);

  const shouldDisplayBulkAction = () => {
    if (activeTabKey === LeadFinderTabKey.PEOPLE_TAB) {
      return !isLeadNoRevealedPresent && !isSelectedAllLeads;
    }
    return !isLeadNoRevealedPresent;
  };

  const getSelectedCount = () =>
    selectedAllLeadsCount > topPeopleLimit &&
    activeTabKey === LeadFinderTabKey.PEOPLE_TAB
      ? topPeopleLimit
      : selectedAllLeadsCount;

  const isSelectedAll = () =>
    (isSelectedAllLeads &&
      !leadsData?.profiles?.some((lead) =>
        deSelectedLeads?.some((l) => l?.id === lead?.id),
      )) ||
    leadsData?.profiles?.every((lead) =>
      selectedLeads?.some((l) => l?.id === lead?.id),
    );
  const isIntermediateAll = () => {
    const tempArr = leadsData?.profiles?.filter((lead) =>
      selectedLeads?.some((l) => l?.id === lead?.id),
    );
    return (
      tempArr?.length + disabledLeads?.length === leadsData?.profiles?.length
    );
  };

  const availableLeadsData = () =>
    leadsData?.profiles?.filter(
      (lead) =>
        !selectedLeads?.some((l) => l?.id === lead?.id) &&
        !disabledLeads?.some((l) => l?.id === lead?.id),
    ).length;

  const handleSelectAllLeads = () => {
    handleSelectAllBulkActionLead(leadsData?.profiles);
  };

  const handleClearSelection = () => {
    handleClearSelectedAllBulkAction();
  };

  const handleBulkReveal = async () => {
    try {
      const leadIds = selectedLeads
        ?.filter((l) => !l?.isRevealed || l?.reReveal)
        .map((l) => l?.id);
      const tagIds = [];
      const newTags = [];

      tagsToAdd.forEach((tag) => {
        if (tag.isNew) {
          newTags.push(tag.name);
        } else {
          tagIds.push(tag.id);
        }
      });

      tagsToRemove.forEach((tag) => {
        tagIds.push(tag);
      });

      setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: true }));
      let bulkRevealRes;
      let leadIdsForPolling = [];
      if (isSelectedAllLeads) {
        const firstPageLeadIds =
          leadsData?.profiles
            ?.filter((ele) => !(ele.isRevealed || ele.isRevealing))
            ?.map((lead) => lead.id) ?? [];

        const currentRevealingLeadIds =
          Object.entries(loadingGetInfoLeads)
            ?.filter((ele) => ele[1])
            ?.map((ele) => Number(ele[0])) ?? [];
        const completedLeadsInFirstPage =
          leadsData?.profiles
            ?.filter(
              (ele) =>
                ele.isRevealed ||
                ele.isRevealing ||
                currentRevealingLeadIds?.includes(ele.id),
            )
            ?.map((ele) => ele.id) ?? [];
        let paginationCount = getSelectedCount();
        if (completedLeadsInFirstPage?.length) {
          paginationCount -= completedLeadsInFirstPage.length;
        }
        bulkRevealRes = await bulkPeopleRevealLeadAPI({
          leadsFilter: apiParam,
          tagIds,
          newTags,
          leadIds: firstPageLeadIds,
          count: paginationCount,
        });
        setLoadingGetInfoLeads((prev) => ({
          ...prev,
          ...Object.fromEntries(firstPageLeadIds.map((lead) => [lead, true])),
        }));
        handleResetStateAfterAnyAction(firstPageLeadIds);
      } else {
        leadIdsForPolling = leadIds;
        setLoadingGetInfoLeads((prev) => ({
          ...prev,
          ...Object.fromEntries(leadIds.map((lead) => [lead, true])),
        }));
        handleResetStateAfterAnyAction(leadIds);
        bulkRevealRes = await bulkRevealLeadAPI({
          leadIds,
          tagIds,
          newTags,
          leadsFilter: apiParam,
        });
      }
      if (bulkRevealRes) {
        const { message, status } = bulkRevealRes.payload;
        if (status === 0) {
          toaster.error(message || SystemErrors.InternalServerError, {
            theme: Theme.New,
          });
        } else if (status === 2) {
          toaster.warning(
            message ||
              'Your another bulk lead reveal action is in progress. Please wait until it finishes',
            { theme: Theme.New },
          );
        } else {
          // ** Handling polling API shouldPoll here
          if (bulkRevealRes?.payload?.shouldPoll) {
            dispatch(enableBulkActionRequestPolling());
          }

          toaster.success(
            message ||
              'Bulk reveal for leads are started. This can take few moments, You will be notified once the process is completed. ',
            { theme: Theme.New },
          );
        }
        handleFetchLeadsAfterBulkActions(true, true, leadIdsForPolling);
        setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: false }));
        setGetContactInfoModalOpen(false);
      }
    } catch (e: any) {
      setLoadingBulkAction((prev) => ({ ...prev, loadingReveal: false }));
      setGetContactInfoModalOpen(false);
      setIsBulkRevealing(false);
      toaster.error(e?.message || SystemErrors.InternalServerError, {
        theme: Theme.New,
      });
    }
  };

  const handleBulkAddToProspect = async () => {
    try {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToProspect: true,
      }));
      let res;
      if (isSelectedAllLeads) {
        res = await bulkSavedAddToProspectLeadAPI({
          deSelectedLeadIds: deSelectedLeads?.map((ele) => ele.id),
          leadsFilter: savedFilters,
        });
      } else {
        const leadIds = selectedLeads.map((l) => l?.id);
        res = await bulkAddToProspectLeadAPI({
          leadIds,
        });
      }
      if (res) {
        toaster.success(res?.payload?.message);
        setLoadingBulkAction((prev) => ({
          ...prev,
          loadingAddToProspect: false,
        }));
        handleFetchLeadsAfterBulkActions(false, true);
        handleResetStateAfterAnyAction();
        setBulkTimeout(
          setTimeout(() => {
            handleFetchLeadsAfterBulkActions(false, true);
          }, BULK_ACTION_TIMEOUT),
        );
      }
    } catch (e: any) {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToProspect: false,
      }));
      toaster.error(e?.message || SystemErrors.InternalServerError);
    }
  };

  const handleBulkExport = async () => {
    let leadIds = [];
    if (selectedLeads.length > 0) {
      leadIds = selectedLeads.map((l) => l?.id);
    }

    try {
      setLoadingBulkAction((prev) => ({ ...prev, loadingExport: true }));
      let result;
      if (leadIds?.length === 0 || isSelectedAllLeads) {
        result = await exportAllLeadsAPI({
          deSelectedLeadIds: deSelectedLeads?.map((ele) => ele.id),
          leadsFilter: savedFilters,
        });
      } else if (leadIds.length > 0) {
        result = await bulkExportLeadAPI({ leadIds });
      } else {
        toaster.error('No lead selected for export', { theme: Theme.New });
        setLoadingBulkAction((prev) => ({ ...prev, loadingExport: false }));
        return;
      }
      const { message, status } = result.payload;
      if (status === 0) {
        toaster.error(message, { theme: Theme.New });
      } else if (status === 2) {
        toaster.warning(message, { theme: Theme.New });
      } else {
        toaster.success(message, { theme: Theme.New });
      }
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingExport: false,
      }));
    } catch (err) {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingExport: false,
      }));
      toaster.error('Error while exporting lead data');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const addContactToStep = async (sequenceId, stepId) => {
    try {
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToSequence: true,
      }));

      let res;
      if (isSelectedAllLeads) {
        res = await bulkSavedAddToSequenceLeadAPI({
          deSelectedLeadIds: deSelectedLeads?.map((ele) => ele.id),
          sequenceId,
          stepId,
          leadsFilter: savedFilters,
        });
      } else {
        const leadIds = selectedLeads.map((l) => l?.id);
        res = await bulkAddToSequenceLeadAPI({
          leadIds,
          sequenceId,
          stepId,
        });
      }

      if (res) {
        toaster.success(res?.payload?.message);
        setLoadingBulkAction((prev) => ({
          ...prev,
          loadingAddToSequence: false,
        }));
        handleFetchLeadsAfterBulkActions(false, true);
        handleResetStateAfterAnyAction();
        setShowAddToSequenceModal(false);
        setBulkTimeout(
          setTimeout(() => {
            handleFetchLeadsAfterBulkActions(false, true);
          }, BULK_ACTION_TIMEOUT),
        );
      }
    } catch (e: any) {
      toaster.error(e?.message || SystemErrors.InternalServerError);
      setShowAddToSequenceModal(false);
      setLoadingBulkAction((prev) => ({
        ...prev,
        loadingAddToSequence: false,
      }));
    }
  };

  // eslint-disable-next-line consistent-return
  const handleBulkAction = (actionType) => {
    let leadIds = [];
    switch (actionType) {
      case BulkLeadsActionType.REVEAL:
        {
          let count = 0;
          if (topPeopleLimit <= 0) {
            return setCreditNotAvailableModalOpen(true);
          }
          if (isSelectedAllLeads) {
            count =
              leadsData?.pagination?.total > topPeopleLimit
                ? topPeopleLimit
                : leadsData?.pagination?.total;
          }
          if (!isSelectedAllLeads) {
            count = selectedLeads
              ?.filter((l) => !l?.isRevealed || l?.reReveal)
              .map((l) => l?.id).length;
          }
          if (count > leadFinderBulkOperationLimit()) {
            return setSelectedMoreThanLimitModalOpen(true);
          } else if (Number(leadCredits) < count) {
            setCreditNotAvailableModalOpen(true);
            // Lead Finder Tripped Feature Event
            window.analytics?.track?.({
              userId: getUserTrackingId(),
              event: AnalyticsEvents.TrippedFeature,
              properties: {
                shAccountId: getUserShAccountId(),
                current_plan: getUserPlanName(),
                role: getUserRole(),
                'LF Plan': getUserLeadFinderPlanName(),
                feature_type: 'Lead Finder',
              },
            });
          } else {
            setGetContactInfoModalOpen(true);
          }
        }
        break;
      case BulkLeadsActionType.ADD_TO_PROSPECT:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          handleBulkAddToProspect();
        }
        break;
      case BulkLeadsActionType.ADD_TO_SEQUENCE:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          setShowAddToSequenceModal(true);
        }
        break;
      case BulkLeadsActionType.EXPORT:
        leadIds = selectedLeads.map((l) => l?.id);
        if (leadIds?.length > leadFinderBulkOperationLimit()) {
          return setSelectedMoreThanLimitModalOpen(true);
        } else {
          handleBulkExport();
        }
        break;
    }
  };

  const upgradePlanhandler = () => {
    history.push({
      pathname: '/settings/billing/subscriptions',
    });
  };

  const getTooltip = (text: string) => (
    <Tooltip
      id="popover-basic"
      className="bs-tooltip-inner checkbox-tooltip-selected"
    >
      {text}
    </Tooltip>
  );

  useEffect(
    () => () => {
      clearTimeout(bulkTimeout);
    },
    [bulkTimeout],
  );

  const handleFetchLeadTags = async () => {
    await fetchLeadTags(true);
  };

  const getLeadFinderExportToolTipText = () => {
    if (isSelectedAllLeads || selectedLeads?.length === 0) {
      return 'Export all saved leads';
    }
    return 'Export selected leads';
  };

  const handleAddTag = async () => {
    await handleFetchLeadsAfterBulkActions(false);
  };

  const handleRemoveTag = async () => {
    await handleFetchLeadsAfterBulkActions(false);
  };

  const customStyles = {
    suggestionsContainer: {
      left: '0',
      width: '29rem',
    },
  };

  useEffect(() => {
    if (getContactInfoModalOpen === true) {
      handleFetchLeadTags();
    }
  }, [getContactInfoModalOpen]);

  return (
    <>
      <div className="leads-filters pl-4">
        {leadsData && leadsData?.profiles?.length > 0 && location?.search && (
          <div className="d-flex align-items-center">
            <span
              className="d-flex select-checkbox-lead"
              onMouseEnter={() => setHoveredSelectAll(true)}
              onMouseLeave={() => setHoveredSelectAll(false)}
            >
              <OverlayTrigger
                placement={Placement.Bottom}
                overlay={getTooltip(
                  LEAD_LISTING_MESSAGES.max_limit_reached_tooltip_msg,
                )}
                show={
                  hoveredSelectAll &&
                  selectedLeads?.length + Number(queryParams.get('take')) >
                    leadFinderBulkOperationLimit() &&
                  !isIntermediateAll() &&
                  leadFinderBulkOperationLimit() - selectedLeads?.length <
                    availableLeadsData()
                }
              >
                <Checkbox
                  checked={isSelectedAll()}
                  className="checkbox-custome"
                  intermediate={isSelectAllIndeterminate}
                  disabled={
                    // isSelectedAllLeads ||
                    selectedLeads?.length + Number(queryParams.get('take')) >
                      leadFinderBulkOperationLimit() &&
                    !isIntermediateAll() &&
                    leadFinderBulkOperationLimit() - selectedLeads?.length <
                      availableLeadsData()
                  }
                  onChange={() => {
                    setIsSelectedAllLeads(false);
                    if (isSelectedAllLeads) {
                      setSelectedLeads([]);
                      return;
                    }
                    if (isSelectAllIndeterminate) {
                      // Deselect all leads on the current page when the checkbox is in an indeterminate state
                      const leadsOnCurrentPage = leadsData?.profiles || [];
                      setSelectedLeads((prevLeads) => {
                        const updatedLeads = prevLeads.filter(
                          (lead) =>
                            !leadsOnCurrentPage?.some(
                              (currentLead) => currentLead?.id === lead?.id,
                            ),
                        );
                        return updatedLeads;
                      });
                    } else {
                      // Otherwise, proceed with the select all logic
                      handleSelectAllLead(leadsData?.profiles);
                    }
                  }}
                />
              </OverlayTrigger>
              <span className="checkbox-lable pl-3">
                {isSelectedAllLeads &&
                selectedAllLeadsCount !== null &&
                selectedAllLeadsCount > 0
                  ? `${getSelectedCount()} Selected`
                  : selectedLeads?.length > 0
                  ? `${selectedLeads?.length} Selected`
                  : 'Select'}
                {selectedLeads.length > 0 ? (
                  <>
                    <span className="divider-vertical" />
                    {isSelectedAllLeads ? (
                      <span
                        role="button"
                        className="semibold-2 blue-txt-11"
                        onClick={() => handleClearSelection()}
                      >
                        Clear selection
                      </span>
                    ) : (
                      <span
                        role="button"
                        className="semibold-2 blue-txt-11"
                        onClick={() => handleSelectAllLeads()}
                      >
                        Select all leads
                      </span>
                    )}
                  </>
                ) : null}
              </span>
            </span>

            <div className="d-flex gap-2 lead-button-container">
              {shouldDisplayGetContactInfo() && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0 || isBulkRevealing}
                  isLoading={isBulkRevealing}
                  loadingText={
                    selectedLeads?.length === 1
                      ? 'Revealing Lead'
                      : 'Revealing Leads'
                  }
                  onClick={() => handleBulkAction(BulkLeadsActionType.REVEAL)}
                  className="btn-leads-user-details btn-custom-secondary"
                >
                  <span className="filter-lable">Get Contact Info</span>
                </Button>
              )}
              {shouldDisplayBulkAction() && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0}
                  isLoading={loadingBulkAction?.loadingAddToProspect}
                  className="btn-leads-user-details btn-custom-secondary"
                  onClick={() => {
                    if (isFreePlan()) {
                      const title =
                        'Please upgrade your plan to start adding prospects';
                      const content = [
                        'Your account is downgraded to a free plan. Upgrade to paid plan to add prospects and resume email sending.',
                      ];

                      planPermissionError.showModal(title, content, true);
                      return;
                    }

                    if (hasResource(ResourceIdentifiers.CONTACTS_IMPORT)) {
                      handleBulkAction(BulkLeadsActionType.ADD_TO_PROSPECT);
                    }
                  }}
                >
                  <UserList className="icon-color" />
                  <span className="filter-lable">Save as Prospect</span>
                </Button>
              )}
              {shouldDisplayBulkAction() && (
                <Button
                  variant="secondary"
                  disabled={selectedLeads?.length === 0}
                  isLoading={loadingBulkAction.loadingAddToSequence}
                  className="btn-leads-user-details btn-custom-secondary"
                  onClick={() => {
                    if (
                      isFreePlan() &&
                      !hasPermission(Permissions.SEQUENCE_PROSPECT_WRITE)
                    ) {
                      const title =
                        'Please upgrade your plan to start adding prospects';
                      const content = [
                        'Your account is downgraded to a free plan. Upgrade to paid plan to add prospects and resume email sending.',
                      ];

                      planPermissionError.showModal(title, content, true);
                      return;
                    }

                    handleBulkAction(BulkLeadsActionType.ADD_TO_SEQUENCE);
                  }}
                >
                  <Send className="icon-color" />
                  <span className="filter-lable">Add to Sequence</span>
                </Button>
              )}
              {!isLeadNoRevealedPresent &&
                activeTabKey === LeadFinderTabKey.SAVED_TAB && (
                  <>
                    <AddRemoveTagButton
                      handleAddTag={handleAddTag}
                      handleRemoveTag={handleRemoveTag}
                      show={true}
                      disabled={selectedLeads?.length === 0}
                      leadTags={leadTags}
                      selectedLeads={selectedLeads}
                      deselectedLeads={deSelectedLeads}
                      selectedAllLeads={isSelectedAllLeads}
                      leadsFilter={savedFilters}
                      fetchLeadTags={fetchLeadTags}
                      setSavedFilters={setSavedFilters}
                      activeTabKey={activeTabKey}
                    />
                  </>
                )}
            </div>
          </div>
        )}
        <div
          className={`total-credits ${
            leadsData && leadsData?.profiles?.length > 0 && location?.search
              ? ''
              : 'ml-auto'
          }`}
        >
          {!isLeadNoRevealedPresent &&
            activeTabKey === LeadFinderTabKey.SAVED_TAB && (
              <OverlayTooltip
                text={getLeadFinderExportToolTipText()}
                key="lead-export"
                placement={Placement.Bottom}
              >
                <Button
                  variant="secondary"
                  isLoading={loadingBulkAction?.loadingExport}
                  className="btn-leads-user-details export-button btn-custom-secondary mr-2"
                  onClick={() => handleBulkAction(BulkLeadsActionType.EXPORT)}
                >
                  <Download className="icon-color" />
                  <span className="filter-lable">Export</span>
                </Button>
              </OverlayTooltip>
            )}
          <div
            className="credit-number cursor-pointer"
            onClick={() =>
              history.push({
                pathname: '/settings/billing/upgrade-plan/lead-finder',
              })
            }
          >
            <Diamond className="diamond-icon" />
            <p>
              {leadCredits ?? 0}{' '}
              {[0, 1].includes(Number(leadCredits)) ? 'Credit' : 'Credits'}
            </p>
          </div>
        </div>
      </div>
      {showAddToSequenceModal && (
        <AddToSequenceModal
          show={showAddToSequenceModal}
          onHide={hideAddToSequenceModal}
          addToSequence={addContactToStep}
          isSubscribed={true}
        />
      )}

      {getContactInfoModalOpen && (
        <GetContactDetail
          show={getContactInfoModalOpen}
          onHide={hideGetContactInfoModal}
          onDelete={() => handleBulkReveal()}
          isLoading={loadingBulkAction?.loadingReveal}
          disableSubmit={loadingBulkAction?.loadingReveal || false}
          title="Want to reveal multiple leads?"
          icon={
            <AlertCircle width={23} height={23} className="orange-txt-14" />
          }
          showCloseIcon={false}
          content={
            <>
              <div className="mt-2">
                <span className="regular-2">
                  {' '}
                  {isSelectedAllLeads
                    ? getSelectedCount()
                    : selectedLeads?.length}{' '}
                  leads are selected. Revealing them might take some time. You
                  will be notified once all of them are revealed.
                </span>
              </div>
              <div className="second-line-get-contact-modal get-contact-modal-tag">
                <span className="regular-2">
                  You can add a custom tag to filter these leads.
                </span>
              </div>

              <TagAutosuggest
                tags={leadTags}
                selectedProspects={selectedLeads}
                tagsToAdd={tagsToAdd}
                setTagsToAdd={setTagsToAdd}
                tagsToRemove={tagsToRemove}
                setTagsToRemove={setTagsToRemove}
                renderMode={RenderMode.Block}
                showAddedTags={false}
                hideNoTagsMessage={true}
                styles={customStyles}
                tagContainerStyle={true}
              />
            </>
          }
          submitButtonText="Continue"
        />
      )}
      {selectedMoreThanLimitModalOpen && (
        <ConfirmationModalV3
          show={selectedMoreThanLimitModalOpen}
          title="Selected More than limit!"
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          contents={[
            `Please select less then ${leadFinderBulkOperationLimit()} leads to proceed!`,
          ]}
          onClose={hideLimitreachedModal}
          onSubmit={hideLimitreachedModal}
          hideCancelButton
          submitButtonText="Ok"
        />
      )}

      {CreditNotAvailableModalOpen && (
        <GetContactDetail
          show={CreditNotAvailableModalOpen}
          onHide={hideCreditNotAvailableModal}
          onDelete={upgradePlanhandler}
          title="Buy credits to see contact information"
          icon={<UserList width={18} height={18} className="orange-txt-14" />}
          content={
            <>
              <div className="mt-2">
                <span className="regular-2 blue-txt-15">
                  You don't have enough lead finder credits to view contact
                  information for selected leads.
                </span>
              </div>
              <div className="second-line-get-contact-modal">
                <span className="regular-2">
                  Please add more credits or upgrade your plan to continue.
                </span>
              </div>
            </>
          }
          submitButtonText="Upgrade"
        />
      )}
    </>
  );
}
