import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  getPollingNotificationsRequest,
  getUserNotificationsRequest,
  getUserUnreadNotificationsRequest,
  markNotificationsAsReadRequest,
  updateHiddenNotificationRequest,
} from '../../extra-actions';
import Header from './header';
import {
  disableBulkActionRequestPolling,
  enableBulkActionRequestPolling,
  markNotificationAsRead,
  resetInitiateFetchUnreadCount,
  resetNotificationData,
  showGettingStartedVideoModal,
  startNotificationBlink,
  stopNotificationBlink,
  updateIsMultichannelActive,
} from '../../home-slice';
import { SequenceIdRouteComponentProps } from '../../../sequence/types';
import { getMyProfileRequest } from '../../../settings/components/my-profile/extra-actions';
import {
  connectEmailAccountRequest,
  getSmtpImapAccountDetailsRequest,
  getEmailAccountsRequest,
  getEmailAccountRequest,
} from '../../../email-accounts/extra-actions';
import { EmailAccountsFilters } from '../../../email-accounts/types/email-account';
import { EmailAccountMethod } from '../../../email-accounts/enums/email-account';

import { UpdateAdminSettingsRequestPayload } from '../../../settings/types/request-payload';
import { updateAdminSettingsRequest } from '../../../settings/components/admin-settings/extra-actions';
import { resetUpdateAdminSettings } from '../../../settings/components/admin-settings/admin-settings-slice';

const mapStateToProps = (state: RootState) => ({
  notifications: state.home.notifications,
  pollingNotifications: state?.home?.pollingNotifications,
  pageHeaderBannerMessage: state.pageHeader.message,
  subscriptionDetails: state.home.subscription,
  ctaURL: state.pageHeader.CTAUrl,
  ctaText: state.pageHeader.CTAText,
  bannerTextTwo: state.pageHeader.bannerMessageTwo,
  paymentActionType: state.pageHeader.paymentActionType,
  target: state.pageHeader.target,
  notificationCount: state.home.notificationCount,
  userProfile: state.myProfile.myProfile,
  getUserNotificationsRequestStatus:
    state.home.markNotificationsAsReadRequest.status,
  agencyConfig: state.home.agencyConfig,
  getUserNotificationsDataRequestStatus:
    state.home.getUserNotificationsRequest.status,
  unreadNotificationCount: state.home.unreadNotificationCount,
  trackingId: state.home.trackingId,
  planName: state.home.subscription?.planName,
  planType: state.home?.subscription?.planType,
  planCode: state.home?.subscription?.planCode,
  remainingProspectLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  ),
  remainingEmailSentLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'EMAIL.ADD'
    ],
    10,
  ),

  meta: state.home.meta,
  client: state.home.client,
  firstName: state.home.adminDetails?.firstName,
  getUserSettingsRequestStatus: state.home.getUserSettingsRequest.status,
  leadFinderCredits:
    state?.home?.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'LEAD.REVEAL'
    ] ?? 0,

  getPostLoadMetaRequestStatus: state.home.getPostLoadMetaRequest.status,
  agencyConfigRequestStatus: state.home?.getAgencyConfigRequest.status,

  isMultichannelActive: state.home.isMultichannelActive,
  updateAdminSettingsRequestStatus:
    state.adminSettings.updateAdminSettingsRequest.status,
  updateAdminSettingsRequestError:
    state.adminSettings.updateAdminSettingsRequest.error,
  shouldBulkActionsPoll: state?.home?.shouldBulkActionsPoll || false,
  verified: state?.home?.verified || false,
  emailAccountSetupInProgress: state.emailAccount.emailAccountSetupInProgress,
  initiateFetchUnreadCount: state?.home?.initiateFetchUnreadCount || false,
});

const mapDispatchToProps = {
  sendGetUserNotificationRequest: (page: number) =>
    getUserNotificationsRequest(page),
  sendGetPollingNotificationsRequest: (cancelToken: any) =>
    getPollingNotificationsRequest(cancelToken),
  sendUpdateHiddenNotificationRequest: (notificationId: string) =>
    updateHiddenNotificationRequest(notificationId),
  sendMarkNotificationAsReadRequest: (notificationId?: string) =>
    markNotificationsAsReadRequest(notificationId),
  markNotificationAsRead: (notificationId?: string) =>
    markNotificationAsRead({ notificationId }),
  sendGetProfileRequest: () => getMyProfileRequest(),
  sendGetUserUnreadNotification: () => getUserUnreadNotificationsRequest(),
  showGettingStartedVideoModal: (show: boolean) =>
    showGettingStartedVideoModal({ show }),
  resetNotificationData: () => resetNotificationData(),
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  sendGetSmtpImapAccountDetailsRequest: (emailAccountId: number) =>
    getSmtpImapAccountDetailsRequest(emailAccountId),
  sendGetEmailAccountsRequest: (filters: EmailAccountsFilters) =>
    getEmailAccountsRequest(filters),
  sendGetEmailAccountRequest: (id: string | number) =>
    getEmailAccountRequest(id),

  sendUpdateAdminDetailsRequest: (payload: UpdateAdminSettingsRequestPayload) =>
    updateAdminSettingsRequest(payload),
  resetUpdateAdminSettings: () => resetUpdateAdminSettings(),
  updateIsMultichannelActive: () => updateIsMultichannelActive(),
  enableBulkActionRequestPolling: () => enableBulkActionRequestPolling(),
  disableBulkActionRequestPolling: () => disableBulkActionRequestPolling(),
  startNotificationBlink: () => startNotificationBlink(),
  stopNotificationBlink: () => stopNotificationBlink(),
  resetInitiateFetchUnreadCount: () => resetInitiateFetchUnreadCount(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  SequenceIdRouteComponentProps;

export default withRouter(connector(Header));
