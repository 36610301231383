/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import axios from 'axios';
import { AlertCircle } from '@saleshandy/icons';
import { Images } from '../../../../shared/app-constants';
import ImageIcon from '../../../../shared/components/images/image-icon';
import TruncatedTextWithTooltip from '../../../../shared/components/truncated-text-with-tooltip';
import Banner from '../../../../shared/design-system/components/atoms/banner';
import Notification from '../../../../shared/design-system/components/atoms/notification';
import ProfileDropdown from '../../../../shared/design-system/components/atoms/profile-dropdown';
import EmailWarmupHeader from '../../../email-warmup/components/email-warmup-header';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import SequenceSingleHeader from '../../../sequence/components/sequence-single-header';
import ChangeLog from './components/change-log';
import { OverlayTooltip } from '../../../../shared/design-system/components/overlay/tooltip/overlay-tooltip';
import type { IProps } from './header-container';
import {
  getCtaText,
  getHeaderHelper,
  getProgressBarCompletionText,
  getProgressBarPercentage,
  getProgressBarText,
  routeStateChange,
} from './header-helper';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import HelpscoutBeaconEvents from '../../../../shared/enums/helpscout-beacon-events';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../shared/utils/execute-on-request-status';
import { getEmailBody } from './utils/helper';
import {
  getIsRequestPending,
  redirectWithoutRefresh,
} from '../../../../shared/utils';
import { contactAdmin } from '../../../../shared/utils/contact-admin';
import { checkIsLtdPlan } from '../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import { IntercomEvents } from '../../../../shared/enums/intercom-events';
import {
  getAgencyUserRole,
  isAgencyUser,
  isWhitelabelWithClientView,
  isWhitelabelWithoutClientView,
} from '../../../../shared/utils/user-details';
import { UserRole } from '../../../../shared/enums/user-roles';
import { Routes as GlobalRoutes } from '../../../../shared/routes';
import HeaderBanner from './components/header-banner';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import EmailAccountsHeader from '../../../email-accounts/components/email-accounts-content/components/email-accounts-header';
import CreateEmailInfrastructureHeader from '../../../email-accounts/components/create-email-infrastructure/components/create-email-infrastructure-header';
import AddEmailAccountsHeader from '../../../email-accounts/components/add-email-accounts/components/add-email-accounts-header';

import { ShAccountSettingsCode } from '../../../settings/enums/admin-settings';
import Modal from '../../../../shared/design-system/ui/modal';
import toaster from '../../../../shared/toaster';
import OnScreenAsyncProgressBar from '../../../../shared/components/async-progress-bar';
import { usePolling } from '../../../../shared/hooks/usePolling';
import { usePageVisibility } from '../../../../shared/utils/hooks';
import { ExceptionTypes } from '../../../../shared/types';
import { NotificationType } from '../../types';

export enum Routes {
  SEQUENCE = 'sequence',
  TASKS = 'tasks',
  UNIFIED_INBOX = 'conversations',
  AGENCY_CLIENT = 'clients',
  PROSPECTS = 'prospects',
  EMAIL_ACCOUNTS = 'email-accounts',
  DOMAINS = 'domains',
  TEMPLATES = 'templates',
  REPORTS = 'reports',
  EMAIL_INSIGHTS = 'email-insights',
  EMAIL_WARMUP = 'warmup',
  GROWTH_HUB = 'growth-hub',
  AGENCY_PORTAL = 'agency-portal',
  SETTINGS = 'settings',
  LEADS = 'leads',
}

const Header: React.FC<IProps> = ({
  location: { pathname },
  sendGetUserNotificationRequest,
  notifications,
  pollingNotifications,
  sendMarkNotificationAsReadRequest,
  match,
  pageHeaderBannerMessage,
  ctaText,
  ctaURL,
  bannerTextTwo,
  paymentActionType,
  target,
  notificationCount,
  markNotificationAsRead,
  userProfile,
  sendGetProfileRequest,
  getUserNotificationsRequestStatus,
  agencyConfig,
  sendGetUserUnreadNotification,
  unreadNotificationCount,
  showGettingStartedVideoModal,
  trackingId,
  planName,
  planType,
  planCode,
  remainingProspectLimit,
  remainingEmailSentLimit,
  getUserSettingsRequestStatus,
  meta,
  subscriptionDetails,
  firstName,
  resetNotificationData,
  getUserNotificationsDataRequestStatus,
  leadFinderCredits,
  client,
  getPostLoadMetaRequestStatus,
  agencyConfigRequestStatus,
  sendConnectEmailAccountRequest,
  sendGetSmtpImapAccountDetailsRequest,
  sendGetEmailAccountRequest,
  sendGetEmailAccountsRequest,
  isMultichannelActive,
  updateAdminSettingsRequestStatus,
  updateAdminSettingsRequestError,
  updateIsMultichannelActive,
  sendUpdateAdminDetailsRequest,
  resetUpdateAdminSettings,
  sendGetPollingNotificationsRequest,
  shouldBulkActionsPoll,
  enableBulkActionRequestPolling,
  disableBulkActionRequestPolling,
  sendUpdateHiddenNotificationRequest,
  verified,
  startNotificationBlink,
  stopNotificationBlink,
  emailAccountSetupInProgress,
  initiateFetchUnreadCount,
  resetInitiateFetchUnreadCount,
}) => {
  const isMultichannelActiveToggled = useRef(false);

  const pollingCancelRef = useRef(null);

  const { t } = useTranslation();
  const sequenceRoutes = [
    'steps',
    'prospects',
    'sequence-score',
    'settings',
    'emails',
  ];
  const emailWarmupRoutes = ['report'];

  const [headerTitle, setHeaderTitle] = useState('Sequence');
  const [singleSequenceHeader, setSingleSequenceHeader] = useState(false);
  const [emailWarmupHeader, setEmailWarmupHeader] = useState<boolean>(false);
  const [
    emailWarmupHeaderWithReport,
    setEmailWarmupHeaderWithReport,
  ] = useState<boolean>(false);
  const [emailAccountsHeader, setEmailAccountsHeader] = useState(false);
  const [
    createEmailInfrastructureHeader,
    setCreateEmailInfrastructureHeader,
  ] = useState(false);
  const [addEmailAccountsHeader, setAddEmailAccountsHeader] = useState(false);
  const [page, setPage] = useState(1);
  const [notificationId, setNotificationId] = useState<string>();

  const [subscriptionEndDays, setSubscriptionEndDays] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [isYoutubeIconVisible, setIsYoutubeIconVisible] = useState<boolean>(
    false,
  );

  const [
    showMultichannelBetaAccessConfirmationModal,
    setShowMultichannelBetaAccessConfirmationModal,
  ] = useState(false);

  const isPageVisible = usePageVisibility();

  const [progressBars, setProgressBars] = useState([]);
  const [hiddenNotification, setHiddenNotification] = useState<string[]>([]);

  const handleAsyncToasterClose = (actionTakenOnNotificationId: string) => {
    stopNotificationBlink();
    sendUpdateHiddenNotificationRequest(actionTakenOnNotificationId);
    setHiddenNotification([...hiddenNotification, actionTakenOnNotificationId]);

    // First set isClosing to true for the target bar
    setProgressBars((prev) =>
      prev.map((bar) =>
        bar?._id === actionTakenOnNotificationId
          ? {
              ...bar,
              isClosing: true,
              isEntering: false,
              active: false,
            }
          : bar,
      ),
    );

    // Remove the bar after animation completes
    setTimeout(() => {
      setProgressBars((prev) =>
        prev.filter((bar) => bar?._id !== actionTakenOnNotificationId),
      );
    }, 300); // Match this with your CSS transition duration

    startNotificationBlink();

    setTimeout(() => {
      stopNotificationBlink();
    }, 2000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCompletion = (
    actionTakenOnNotificationId: string,
    closeTime = 3000,
  ) => {
    setTimeout(() => {
      handleAsyncToasterClose(actionTakenOnNotificationId);
    }, closeTime);
  };

  const getNewOnscreenNotifications = (newNotifications) =>
    newNotifications
      ?.filter(
        (notification) =>
          notification?.onScreen &&
          !hiddenNotification.includes(notification._id),
      )
      ?.slice(0, 5)
      ?.map((notification) => ({
        ...notification,
        isClosing: false,
        isEntering: false,
        active: false,
      })) || [];

  const handleNotificationPollingRequest = useCallback(async () => {
    if (pollingCancelRef?.current === null) {
      (pollingCancelRef as any).current = axios?.CancelToken?.source();
    } else {
      pollingCancelRef?.current?.cancel({
        type: ExceptionTypes.Silent,
        message: 'POLLING_API_CANCELLED',
      });
      pollingCancelRef.current = null;
    }

    const response = await sendGetPollingNotificationsRequest(
      pollingCancelRef?.current?.token || null,
    );

    if (response.payload.payload?.notifications?.length > 0) {
      const newNotification = response.payload.payload?.notifications || [];
      const onScreenNotifications = getNewOnscreenNotifications(
        newNotification,
      );
      let currentProgressBarValue = [];
      setProgressBars((prev) => {
        currentProgressBarValue = prev;
        return prev;
      });

      const newBars =
        onScreenNotifications?.filter(
          (notification) =>
            !currentProgressBarValue?.some(
              (bar) => bar._id === notification._id,
            ),
        ) || [];

      const existingBarsWithUpdates =
        onScreenNotifications?.filter((notification) =>
          currentProgressBarValue?.some((bar) => bar._id === notification._id),
        ) || [];

      if (newBars?.length > 0) {
        // Add new bars with initial state
        setProgressBars((prev) =>
          getNewOnscreenNotifications([...newBars, ...prev]),
        );

        // Trigger entering animation
        requestAnimationFrame(() => {
          setProgressBars((prev) =>
            prev.map((bar) =>
              newBars.some((newBar) => newBar._id === bar._id)
                ? { ...bar, isEntering: true }
                : { ...bar, active: true },
            ),
          );
        });

        // Set to active state after entering animation
        setTimeout(() => {
          setProgressBars((prev) =>
            prev.map((bar) =>
              newBars.some((newBar) => newBar._id === bar._id)
                ? { ...bar, isEntering: false, active: true }
                : { ...bar, active: true },
            ),
          );
        }, 300); // Match this with your CSS transition duration
      }
      if (existingBarsWithUpdates?.length > 0) {
        setProgressBars((prev) =>
          prev.map((bar) => {
            const update = existingBarsWithUpdates?.find(
              (notification) => notification._id === bar._id,
            );
            if (update) {
              return {
                ...bar,
                ...update,
                isEntering: bar.isEntering,
                active: bar.active,
              };
            }
            return bar;
          }),
        );
      }
    }

    if (response?.payload?.payload?.shouldPoll) {
      enableBulkActionRequestPolling();
    } else {
      disableBulkActionRequestPolling();
    }
  }, [
    sendGetPollingNotificationsRequest,
    enableBulkActionRequestPolling,
    disableBulkActionRequestPolling,
    hiddenNotification,
  ]);

  const handleShowMultichannelBetaAccessConfirmationModal = () => {
    setShowMultichannelBetaAccessConfirmationModal(true);
  };

  const handleCloseMultichannelBetaAccessConfirmationModal = () => {
    setShowMultichannelBetaAccessConfirmationModal(false);
  };

  const getHeader = (locationPathName) =>
    getHeaderHelper(locationPathName, Routes);

  const openHelpScoutBeacon = () => {
    openChatSupport();
  };

  const handleUpgradePlanCTA = (isPlanExpired = false) => {
    if (checkIsLtdPlan(planType)) {
      openHelpScoutBeacon();
      return;
    }

    const emailSubject = () => {
      if (isWhitelabelWithClientView()) {
        return isPlanExpired
          ? 'Request to upgrade our account.'
          : 'Request to upgrade plan';
      }

      return isPlanExpired
        ? t('labels.upgrade_to_saleshandy_email_sub')
        : t('messages.upgrade_plan_error_email_subject');
    };

    const emailBody = isPlanExpired
      ? `Hey ${firstName}, %0D%0A %0D%0A ${t(
          'messages.upgrade_to_pro_email_body',
        )}`
      : getEmailBody();

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      const path = pathname.split('/')?.[1];
      if (path === Routes.LEADS) {
        redirectWithoutRefresh(
          GlobalRoutes.Setting_Billing_Upgrade_Lead_Finder_Plan,
        );
      } else {
        redirectWithoutRefresh(
          GlobalRoutes.Setting_Billing_Upgrade_Email_Outreach_Plan,
        );
      }
    } else if (isAgencyUser()) {
      const agencyUserRole = getAgencyUserRole();
      const agencyEmailSubject = `${t(
        'messages.saleshandy_agency_account_upgrade_required',
      )}`;
      if (agencyUserRole && agencyUserRole === UserRole.AGENCYOWNER) {
        const supportEmail = process.env.REACT_APP_CONTACT_SUPPORT_EMAIL;

        contactAdmin(agencyEmailSubject, emailBody, supportEmail);
      }
      contactAdmin(agencyEmailSubject, emailBody);
    } else {
      contactAdmin(emailSubject(), emailBody);
    }
  };

  const setAnalyticsTrackData = () => {
    // Send data to analytics
    window.analytics?.track?.({
      userId: trackingId,
      event: AnalyticsEvents.BeaconOpened,
      properties: {
        current_tab_url: document.location.href,
        current_plan: planName,
      },
    });
  };

  const handleGetMultichannelAccess = () => {
    const settings = [
      {
        code: ShAccountSettingsCode.IsMultichannelActive,
        value: '1',
      },
    ];

    sendUpdateAdminDetailsRequest({
      settings,
    });
    isMultichannelActiveToggled.current = true;
  };

  useEffect(() => {
    setIsYoutubeIconVisible(
      agencyConfig?.showYTVideoGuide &&
        headerTitle?.trim()?.toLowerCase() === 'sequence' &&
        isWhitelabelWithoutClientView() &&
        (!getIsRequestPending(agencyConfigRequestStatus) || isInternalUrl()),
    );
  }, [headerTitle]);

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('on', HelpscoutBeaconEvents.OPEN, () => {
        setAnalyticsTrackData();
      });
    }
    if (window.Intercom) {
      window.Intercom(IntercomEvents.ON_SHOW, () => {
        setAnalyticsTrackData();
      });
    }
  }, [window.Beacon, window.Intercom]);

  useEffect(() => {
    // Initializing headwat change-log
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);

    const config = {
      selector: '#headway-badge',
      account: '7eoAWy',
      position: {
        y: 40,
        x: 0,
      },
    };
    script.onload = () => {
      window?.Headway?.init?.(config);
    };

    // Getting user profile
    sendGetProfileRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = pathname.split('/');

    routeStateChange({
      getHeader,
      path,
      Routes,
      sequenceRoutes,
      setSingleSequenceHeader,
      emailWarmupRoutes,
      setEmailWarmupHeader,
      setEmailWarmupHeaderWithReport,
      setCreateEmailInfrastructureHeader,
      setAddEmailAccountsHeader,
      setEmailAccountsHeader,
      setHeaderTitle,
    });

    // Update Intercom
    if (window.Intercom) {
      window.Intercom(IntercomEvents.UPDATE);
    }
  }, [pathname, remainingProspectLimit, remainingEmailSentLimit]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserNotificationsRequestStatus,
      onSuccess: () => {
        markNotificationAsRead(notificationId);
      },
    });
  }, [getUserNotificationsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: () => {
        setSubscriptionPlan(subscriptionDetails?.planCode);
        setSubscriptionEndDays(
          Math.ceil(
            DateTime.fromJSDate(new Date(subscriptionDetails?.nextBillingAt), {
              zone: 'Etc/UTC',
            })
              .diff(DateTime.now().toUTC(), ['days'])
              .toObject().days,
          ),
        );
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, subscriptionDetails]);

  useEffect(() => {
    if (initiateFetchUnreadCount) {
      sendGetUserUnreadNotification();
      resetInitiateFetchUnreadCount();
    }
  }, [initiateFetchUnreadCount]);

  useEffect(() => {
    if (isMultichannelActiveToggled.current) {
      executeOnRequestStatus({
        status: updateAdminSettingsRequestStatus,
        onSuccess: () => {
          toaster.success('Multi-channel enabled successfully');
          isMultichannelActiveToggled.current = false;
          updateIsMultichannelActive();
          resetUpdateAdminSettings();
          handleCloseMultichannelBetaAccessConfirmationModal();
        },
        onFailed: () => {
          resetUpdateAdminSettings();
          handleCloseMultichannelBetaAccessConfirmationModal();
          isMultichannelActiveToggled.current = false;
          executeOnErrorWithErrorCheck({
            error: updateAdminSettingsRequestError,
            onError: () => {
              toaster.error(updateAdminSettingsRequestError.message);
            },
          });
        },
      });
    }
  }, [updateAdminSettingsRequestStatus]);

  useEffect(() => {
    if (progressBars?.length === 5) {
      const lastBarId = progressBars?.[4]?._id;
      if (lastBarId) {
        handleCompletion(lastBarId, 1000);
      }
    }
  }, [progressBars]);

  // ** Polling function getting called
  usePolling(
    handleNotificationPollingRequest,
    5000,
    shouldBulkActionsPoll && isPageVisible,
  );

  const markNotificationsAsRead = async (
    notificationIdToMarkAsRead?: string,
  ) => {
    await sendMarkNotificationAsReadRequest(notificationIdToMarkAsRead);
    setNotificationId(notificationIdToMarkAsRead);
  };

  const fetchNextNotifications = () => {
    setPage(page + 1);
    sendGetUserNotificationRequest(page + 1);
  };
  const resetNotificationDataAndPage = () => {
    setPage(1);
    resetNotificationData();
    sendGetUserNotificationRequest(1);
  };

  const getRedirectUrlFromNotification = (notification) => {
    const fileId = notification?.metaData?.importFile?.id || '';
    switch (notification?.notificationType) {
      case NotificationType.ProspectImportAsync:
        return `/prospects?importedFile=${fileId}&notificationId=${
          notification?._id || ''
        }`;
      case NotificationType.SequenceProspectImportAsync:
        return `/sequence/${
          notification?.sequenceId || ''
        }/prospects?importedFile=${fileId}&notificationId=${
          notification?._id || ''
        }`;
      case NotificationType.LeadRevealCompletedAsync:
      case NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync:
        if (notification.reportUrl && notification.reportUrl !== '') {
          return notification.reportUrl?.replace(
            process.env.REACT_APP_BASE_URL,
            '',
          );
        }
        return '';
      default:
        return '';
    }
  };

  const getHeaderContent = () => {
    if (singleSequenceHeader) {
      return <SequenceSingleHeader currentTab={match.params.activeTab} />;
    }

    if (emailWarmupHeader) {
      return (
        <EmailWarmupHeader
          emailWarmupHeaderWithReport={emailWarmupHeaderWithReport}
        />
      );
    }

    if (emailAccountsHeader) {
      return <EmailAccountsHeader />;
    }

    if (createEmailInfrastructureHeader) {
      return <CreateEmailInfrastructureHeader />;
    }

    if (addEmailAccountsHeader) {
      return <AddEmailAccountsHeader />;
    }

    return (
      <div className="d-flex align-items-center">
        <h2 className="semibold-3 mb-0 popover-arrow-color-txt">
          {headerTitle}
        </h2>
        {isYoutubeIconVisible && (
          <OverlayTooltip text="Learn how to create sequence.">
            <ImageIcon
              src={Images.Youtube}
              onClick={() => showGettingStartedVideoModal(true)}
              className="ml-2 pointer"
            />
          </OverlayTooltip>
        )}
      </div>
    );
  };

  const isEmailAccountsPage = pathname === '/email-accounts';

  const pageHeaderClassNames = classNames([
    'page-header',
    {
      noBoxShadow: createEmailInfrastructureHeader || singleSequenceHeader,
      noBorderNoBoxShadow:
        headerTitle === 'Email Accounts' &&
        emailAccountSetupInProgress &&
        isEmailAccountsPage,
    },
  ]);

  return (
    <>
      <Row className={pageHeaderClassNames}>
        <div className="p-0">
          <div className="d-flex header-with-notification">
            {getHeaderContent()}
          </div>
        </div>
        <div className="w-auto text-right justify-content-end align-items-center d-flex p-0">
          <HeaderBanner
            subscriptionPlan={subscriptionPlan}
            subscriptionEndDays={subscriptionEndDays}
            pathname={pathname}
            leadFinderCredits={leadFinderCredits}
            handleUpgradePlanCTA={handleUpgradePlanCTA}
            isPostLoadMetaRequestPending={getIsRequestPending(
              getPostLoadMetaRequestStatus,
            )}
          />
          {hasPermission(Permissions.WHATSANEW_SHOW) && (
            <span className="ml-3">
              <ChangeLog />
            </span>
          )}
          <span className="ml-3">
            {hasPermission(Permissions.NOTIFICATION_READ) && (
              <Notification
                notifications={notifications}
                markAsRead={markNotificationsAsRead}
                notificationCount={notificationCount}
                fetchNextNotifications={fetchNextNotifications}
                timeZone={userProfile.timeZone}
                sendGetUserUnreadNotification={sendGetUserUnreadNotification}
                userUnreadNotificationCount={unreadNotificationCount}
                trackingId={trackingId}
                isHasPermissionToCallUnreadNotification={
                  planCode && hasPermission(Permissions.NOTIFICATION_READ)
                }
                resetNotificationData={resetNotificationDataAndPage}
                getUserNotificationsDataRequestStatus={
                  getUserNotificationsDataRequestStatus
                }
                sendConnectEmailAccountRequest={sendConnectEmailAccountRequest}
                sendGetSmtpImapAccountDetailsRequest={
                  sendGetSmtpImapAccountDetailsRequest
                }
                sendGetEmailAccountRequest={sendGetEmailAccountRequest}
                sendGetEmailAccountsRequest={sendGetEmailAccountsRequest}
                pollingNotifications={pollingNotifications}
              />
            )}
          </span>
          <span>
            <ProfileDropdown
              userProfile={userProfile}
              client={client}
              trackingId={trackingId}
              isMultichannelActive={isMultichannelActive}
              handleShowMultichannelBetaAccessConfirmationModal={
                handleShowMultichannelBetaAccessConfirmationModal
              }
            />
          </span>
          {agencyConfig?.showClientName && agencyConfig?.clientName && (
            <TruncatedTextWithTooltip
              string={agencyConfig?.clientName || ''}
              length={20}
              textClassName="agency--client-name font-14 font-semibold"
            />
          )}
          {!agencyConfig?.showClientName && client?.id && (
            <TruncatedTextWithTooltip
              string={client?.companyName || ''}
              length={20}
              textClassName="agency--client-name font-14 font-semibold"
            />
          )}
        </div>
      </Row>

      <div
        className="async-stacked-bar"
        style={{ top: verified ? '3.125rem' : '5.188rem' }}
      >
        {progressBars.length > 0 &&
          progressBars.map((notification) => {
            const redirectUrl = getRedirectUrlFromNotification(notification);
            return (
              <OnScreenAsyncProgressBar
                key={`notification-${notification?.uuid || notification?._id}`}
                show={notification?.onScreen}
                percentage={getProgressBarPercentage(
                  notification?.metaData?.completedCount,
                  notification?.metaData?.totalCount,
                )}
                textPercentage={`${getProgressBarPercentage(
                  notification?.metaData?.completedCount,
                  notification?.metaData?.totalCount,
                )}%`}
                text={getProgressBarText(
                  notification?.notificationType,
                  notification?.metaData?.totalCount,
                )}
                completionText={getProgressBarCompletionText(
                  notification?.notificationType,
                  notification?.metaData?.totalCount,
                )}
                ctaText={getCtaText(notification?.notificationType)}
                ctaRedirectLink={redirectUrl}
                onClose={() => handleAsyncToasterClose(notification?._id)}
                isComplete={notification?.completedAt}
                onCompletion={() => handleCompletion(notification?._id)}
                classNameSuffix={`${
                  notification?.isEntering ? 'entering' : ''
                } ${notification?.active ? 'active' : ''} ${
                  notification?.isClosing ? 'closing' : ''
                }`}
              />
            );
          })}
      </div>
      {pageHeaderBannerMessage && (
        <div>
          <Banner
            pageHeaderBannerMessage={pageHeaderBannerMessage}
            style={{
              position: 'sticky',
              top: 0,
              marginBottom: '0%',
              borderRadius: '0%',
            }}
            ctaText={ctaText}
            ctaURL={ctaURL}
            target={target}
            bannerTextTwo={bannerTextTwo}
            paymentActionType={paymentActionType}
          />
        </div>
      )}

      <Modal
        show={showMultichannelBetaAccessConfirmationModal}
        className="activate-multichannel-confirmation-modal"
        title={
          <div className="d-flex align-items-center gap-10">
            <AlertCircle width={24} height={24} color="#1D4ED8" />
            <span className="line-height-1">Multi-channel Access</span>
          </div>
        }
        onHide={handleCloseMultichannelBetaAccessConfirmationModal}
        primaryBtnLabel="Get Access"
        secondaryBtnLabel="Cancel"
        primaryBtnClickedHandler={handleGetMultichannelAccess}
        secondaryBtnClickedHandler={
          handleCloseMultichannelBetaAccessConfirmationModal
        }
        primaryBtnDisabled={getIsRequestPending(
          updateAdminSettingsRequestStatus,
        )}
        primaryBtnLoading={getIsRequestPending(
          updateAdminSettingsRequestStatus,
        )}
      >
        <p>
          Great, you are going to enable multi-channel, that will allow you to
          create a sequence having LinkedIn, Call, and Manual tasks along with
          Email steps.
        </p>
        <p>Once enabled, multi-channel access cannot be disabled.</p>
      </Modal>
    </>
  );
};

export default Header;
