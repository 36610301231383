/* eslint-disable no-underscore-dangle */
import classNames from 'classnames';
import {
  NotificationAction,
  NotificationReason,
  Notifications,
  NotificationType,
} from '../../../../../../components/home/types';
import { EmailAccountType } from '../../../../../../components/email-accounts/enums/email-account';
import { Images } from '../../../../../app-constants';
import {
  getAgencyConfigDetails,
  isWhitelabelWithClientView,
} from '../../../../../utils/user-details';

const quoteRegex = /(“.*?”)/g;
const quoteRegexForEmail = /(”.*?”)/g;

export const getRedirectUrl = (notification: Notifications) => {
  const reactAppUrl = isWhitelabelWithClientView()
    ? getAgencyConfigDetails()?.clientBaseUrl || ''
    : process.env.REACT_APP_BASE_URL;

  const notificationRedirectionUrl = `${reactAppUrl}/email-accounts`;
  const notificationUrlParams = `emailAccountId=${notification.emailAccountId}&emailAccountType=${notification.emailAccountType}`;

  switch (notification.notificationType) {
    case NotificationType.EmailAccountDisconnected:
    case NotificationType.EmailAccountPaused:
      return notification.emailAccountType === EmailAccountType.SmtpImap
        ? `${notificationRedirectionUrl}?${notificationUrlParams}`
        : notificationRedirectionUrl;
    case NotificationType.SequencePaused:
    case NotificationType.SequencePausedBounce:
      return `${reactAppUrl}/sequence/${notification.sequenceId}/steps`;
    case NotificationType.UniboxScheduleCanceled:
      return `${reactAppUrl}/conversations/${encodeURIComponent(
        notification.emailThreadHashId,
      )}?page=1&type=draft&search=${encodeURIComponent(
        notification.prospectEmail,
      )}`;
    case NotificationType.LeadRevealCompleted:
    case NotificationType.LeadRevealCompletedNoEmailOrPhone:
      return notification.reportUrl;
    case NotificationType.ProspectImportAsync:
      return `${reactAppUrl}/prospects?importedFile=${
        notification?.metaData?.importFile?.id ?? ''
      }&notificationId=${notification._id ?? ''}`;
    case NotificationType.SequenceProspectImportAsync:
      return `${reactAppUrl}/sequence/${
        notification?.sequenceId ?? ''
      }/prospects?importedFile=${
        notification?.metaData?.importFile?.id ?? ''
      }&notificationId=${notification._id ?? ''}`;
    case NotificationType.LeadRevealCompletedAsync:
    case NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync:
      return (
        notification.reportUrl ?? `${reactAppUrl}/leads?start=1&take=25#saved`
      );
    case NotificationType.DomainRegistrationComplete:
      return notification.reportUrl;
    default:
      return '';
  }
};

export const moreNotificationExist = (
  notificationsLength: number,
  notificationCount: number,
) => notificationsLength < notificationCount;

export const getNotificationType = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.UniboxScheduleCanceled
  ) {
    return NotificationAction.ViewDraft;
  }
  if (notification.notificationType === NotificationType.WhitelabelDomainSet) {
    return NotificationAction.Login;
  }
  if (
    notification.notificationType === NotificationType.LeadRevealCompleted ||
    notification.notificationType ===
      NotificationType.LeadRevealCompletedNoEmailOrPhone
  ) {
    return NotificationAction.Leads;
  }

  if (notification?.reportUrl) {
    const proscpectNotifications = [
      NotificationType.SequenceProspectImportAsync,
      NotificationType.ProspectImportAsync,
    ];

    const leadNotifications = [
      NotificationType.LeadRevealCompletedAsync,
      NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync,
    ];

    if (proscpectNotifications?.includes(notification.notificationType)) {
      return NotificationAction.ViewList;
    }

    if (leadNotifications?.includes(notification.notificationType)) {
      return NotificationAction.ViewLeads;
    }

    return NotificationAction.Download;
  }

  if (
    notification.notificationType === NotificationType.SequenceProspectImport
  ) {
    return '';
  }

  if (notification.notificationType === NotificationType.EmailAccountPaused) {
    return NotificationAction.Activate;
  }

  if (
    notification.notificationType === NotificationType.ProspectImportAsync ||
    notification.notificationType ===
      NotificationType.SequenceProspectImportAsync
  ) {
    return NotificationAction.ViewList;
  }

  if (
    notification.notificationType ===
      NotificationType.LeadRevealCompletedAsync ||
    notification.notificationType ===
      NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync
  ) {
    return NotificationAction.ViewLeads;
  }
  if (
    notification.notificationType ===
    NotificationType.DomainRegistrationComplete
  ) {
    return NotificationAction.ViewEmailAccounts;
  }

  return notification.notificationType ===
    NotificationType.EmailAccountDisconnected
    ? NotificationAction.Reconnect
    : NotificationAction.Resume;
};

export const getRegexForEmail = (notification: Notifications) =>
  notification.notificationType === NotificationType.EmailAccountDisconnected
    ? quoteRegexForEmail
    : quoteRegex;

export const getNotificationReason = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.UniboxScheduleCanceled
  ) {
    return NotificationReason.UniboxScheduleCanceled;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceProspectExportForClient
  ) {
    return NotificationReason.SequenceProspectExportForClient;
  }
  if (notification.notificationType === NotificationType.SequenceReportClient) {
    return NotificationReason.SequenceReportClient;
  }
  if (
    notification.notificationType ===
    NotificationType.UniboxConversationForClient
  ) {
    return NotificationReason.UniboxConversationForClient;
  }
  if (
    notification.notificationType ===
    NotificationType.EmailAccountBulkConnectClient
  ) {
    return NotificationReason.EmailAccountBulkConnectClient;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceBulkProspectClient
  ) {
    return NotificationReason.SequenceBulkProspectClient;
  }
  if (notification.notificationType === NotificationType.WhitelabelDomainSet) {
    return NotificationReason.WhitelabelDomainSet;
  }
  if (notification.notificationType === NotificationType.DnsImport) {
    return NotificationReason.DnsImport;
  }
  if (notification.notificationType === NotificationType.DnsExport) {
    return NotificationReason.DnsExport;
  }
  if (
    notification.notificationType === NotificationType.SequenceProspectImport
  ) {
    return NotificationReason.SequenceProspectImport;
  }
  if (notification.notificationType === NotificationType.LeadRevealCompleted) {
    return NotificationReason.LeadRevealCompleted;
  }
  if (
    notification.notificationType ===
    NotificationType.LeadRevealCompletedNoEmailOrPhone
  ) {
    return NotificationReason.LeadRevealCompletedNoEmailOrPhone;
  }
  if (
    notification.notificationType === NotificationType.SequenceProspectVerify ||
    notification.notificationType === NotificationType.ProspectVerifyReport
  ) {
    return NotificationReason.ProspectVerifyReport;
  }
  if (
    notification.notificationType ===
    NotificationType.SequenceProspectBulkAction
  ) {
    return NotificationReason.SequenceProspectBulkAction;
  }

  if (notification.notificationType === NotificationType.ProspectBulkAction) {
    return NotificationReason.ProspectBulkAction;
  }

  if (notification.notificationType === NotificationType.EmailAccountPaused) {
    return NotificationReason.EmailAccountPaused;
  }

  if (notification.notificationType === NotificationType.ProspectImportAsync) {
    if (notification?.completedAt) {
      return NotificationReason.ProspectImportAsyncCompleted;
    }
    return NotificationReason.ProspectImportAsync;
  }
  if (
    notification.notificationType ===
    NotificationType.DomainRegistrationInProgress
  ) {
    return NotificationReason.DomainRegistrationInProgress;
  }

  if (
    notification.notificationType ===
    NotificationType.MailboxRegistrationComplete
  ) {
    return NotificationReason.MailboxRegistrationComplete;
  }

  if (
    notification.notificationType ===
    NotificationType.SequenceProspectImportAsync
  ) {
    if (notification?.completedAt) {
      return NotificationReason.SequenceProspectImportAsyncCompleted;
    }
    return NotificationReason.SequenceProspectImportAsync;
  }

  if (
    notification.notificationType === NotificationType.LeadRevealCompletedAsync
  ) {
    if (notification?.completedAt) {
      return NotificationReason.LeadRevealCompletedAsyncProcess;
    }
    return NotificationReason.LeadRevealCompletedAsync;
  }

  if (
    notification.notificationType ===
    NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync
  ) {
    if (notification?.completedAt) {
      return NotificationReason.LeadRevealCompletedNoEmailOrPhoneAsyncProcess;
    }
    return NotificationReason.LeadRevealCompletedNoEmailOrPhoneAsync;
  }

  if (
    notification?.notificationType ===
    NotificationType.DomainRegistrationComplete
  ) {
    return NotificationReason.DomainRegistrationComplete;
  }

  return notification.notificationType ===
    NotificationType.EmailAccountDisconnected
    ? NotificationReason.EmailDisconneted
    : NotificationReason.SequencePaused;
};

export const getImageIcon = (notification: Notifications) => {
  if (
    notification.notificationType === NotificationType.LeadRevealCompleted ||
    notification.notificationType ===
      NotificationType.LeadRevealCompletedNoEmailOrPhone ||
    ((notification.notificationType ===
      NotificationType.LeadRevealCompletedAsync ||
      notification.notificationType ===
        NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync) &&
      notification?.completedAt)
  ) {
    return Images.NotificationLeadBulkReveal;
  }

  if (
    (notification.notificationType === NotificationType.ProspectImportAsync ||
      notification.notificationType ===
        NotificationType.SequenceProspectImportAsync ||
      notification.notificationType ===
        NotificationType.LeadRevealCompletedAsync ||
      notification.notificationType ===
        NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync) &&
    !notification?.completedAt
  ) {
    return Images.NotificationRefresh;
  }
  if (
    notification.notificationType === NotificationType.ProspectImportAsync ||
    notification.notificationType ===
      NotificationType.SequenceProspectImportAsync
  ) {
    return Images.NotificationUser;
  }
  if (
    notification.notificationType ===
    NotificationType.DomainRegistrationInProgress
  ) {
    return Images.InfoCircleYellow;
  }

  return [
    NotificationType.EmailAccountDisconnected,
    NotificationType.UniboxScheduleCanceled,
  ].includes(notification.notificationType)
    ? Images.NotificationAlert
    : Images.NotificationPause;
};

export const getImageIconForBulkActionCompleted = () => Images.CheckGreen;

export const getNotificationClassName = (
  showNotifications: boolean,
  showBlink?: boolean,
) =>
  classNames([
    'notification-circle d-flex bs-badge hover-on-header',
    {
      'active-notification': showNotifications,
    },
    {
      'blink-notification': showBlink,
    },
  ]);

export const getNotificationTypeClass = (notification) => {
  if (
    notification?.reportUrl ||
    notification.notificationType === NotificationType.ProspectBulkAction ||
    notification.notificationType ===
      NotificationType.SequenceProspectBulkAction ||
    notification.notificationType === NotificationType.SequenceProspectImport ||
    notification.notificationType ===
      NotificationType.DomainRegistrationComplete ||
    notification.notificationType ===
      NotificationType.MailboxRegistrationComplete
  ) {
    return 'green'; // or 'green' if you prefer
  }

  if (
    [
      NotificationType.LeadRevealCompleted,
      NotificationType.LeadRevealCompletedNoEmailOrPhone,
    ].includes(notification.notificationType)
  ) {
    return 'purple';
  }

  if (
    [NotificationType.DomainRegistrationInProgress].includes(
      notification.notificationType,
    )
  ) {
    return 'yellow';
  }

  return 'default';
};

export const getNotificationClass = (notification) => {
  const notificationType = getNotificationTypeClass(notification);

  if (notificationType === 'green') {
    return 'bs-badge-status bs-badge-status-success bs-badge-dot';
  }

  if (notificationType === 'purple') {
    return 'bs-badge-purple-dot';
  }

  if (notificationType === 'yellow') {
    return 'bs-badge-yellow-dot';
  }

  return 'bs-badge-dot';
};

export const getNotificationClassNameFordotColor = (notification) => {
  const notificationType = getNotificationTypeClass(notification);

  if (notificationType === 'green') {
    return 'notification-green';
  }

  if (notificationType === 'purple') {
    return 'notification-purple';
  }

  if (notificationType === 'yellow') {
    return 'notification-yellow';
  }

  return 'notification-red';
};

export const asyncNotificationTypes = [
  NotificationType.LeadRevealCompleted,
  NotificationType.LeadRevealCompletedNoEmailOrPhone,
  NotificationType.SequenceProspectImportAsync,
  NotificationType.ProspectImportAsync,
  NotificationType.LeadRevealCompletedAsync,
  NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync,
];

export const isProspectBulkOrSequenceBulk = (type: NotificationType) =>
  type !== NotificationType.ProspectBulkAction &&
  type !== NotificationType.SequenceProspectBulkAction;

export const isAsyncOperationNotification = (type: NotificationType) =>
  type === NotificationType.ProspectImportAsync ||
  type === NotificationType.SequenceProspectImportAsync ||
  type === NotificationType.LeadRevealCompletedAsync ||
  type === NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync;

export const isLogVisibleNotificationTypes = [
  NotificationType.ProspectImportAsync,
  NotificationType.SequenceProspectImportAsync,
  NotificationType.LeadRevealCompletedAsync,
  NotificationType.LeadRevealCompletedNoEmailOrPhoneAsync,
  NotificationType.SequenceBulkProspectClient,
  NotificationType.SequenceProspectExportForClient,
  NotificationType.UniboxConversationForClient,
  NotificationType.EmailAccountBulkConnectClient,
  NotificationType.SequenceReportClient,
  NotificationType.WhitelabelDomainSet,
  NotificationType.ProspectVerifyReport,
  NotificationType.SequenceProspectImport,
  NotificationType.DnsImport,
  NotificationType.DnsExport,
  NotificationType.SequenceProspectVerify,
  NotificationType.UniboxScheduleCanceled,
  NotificationType.SequenceProspectBulkAction,
  NotificationType.MailboxRegistrationComplete,
];

export const pausedNotifications = [
  NotificationType.SequencePaused,
  NotificationType.SequencePausedBounce,
  NotificationType.EmailAccountDisconnected,
  NotificationType.EmailAccountPaused,
  NotificationType.DomainRegistrationComplete,
  NotificationType.DomainRegistrationInProgress,
  NotificationType.ProspectBulkAction,
  NotificationType.LeadRevealCompleted,
  NotificationType.LeadRevealCompletedNoEmailOrPhone,
];

export const prospectBulkAndSequence = [
  NotificationType.ProspectBulkAction,
  NotificationType.SequenceProspectImport,
  NotificationType.SequenceProspectBulkAction,
  NotificationType.MailboxRegistrationComplete,
];

export const bulkSequenceAndDomainNotificationTypes = [
  NotificationType.ProspectBulkAction,
  NotificationType.SequenceProspectBulkAction,
  NotificationType.SequenceProspectImport,
  NotificationType.DomainRegistrationComplete,
  NotificationType.MailboxRegistrationComplete,
];

export const isCompletedOperationNotification = [
  NotificationType.LeadRevealCompleted,
  NotificationType.SequenceProspectImport,
  NotificationType.DomainRegistrationComplete,
  NotificationType.EmailAccountDisconnected,
  NotificationType.EmailAccountPaused,
  NotificationType.LeadRevealCompletedNoEmailOrPhone,
  NotificationType.SequencePaused,
];
